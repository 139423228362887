import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button } from 'semantic-ui-react';
import { ORGANIZATION_DOCUMENTS } from '../constants';
import { FormCheckboxField } from '../../../semantic-ui/components/form-checkbox-field';

const AcceptTermsView = ({ isButtonEnabled, onButtonClick }) => {
	return (
		<div className="accept-terms">
			<div className="accept-terms__overlay" />
			<div className="accept-terms__content">
				<div>
					<h2 className="accept-terms__title accept-terms__title_secondary">Персональные данные</h2>
					<h2 className="accept-terms__title accept-terms__title_primary">и условия использования</h2>
				</div>
				<p className="accept-terms__text">
					Для продолжения использования сервиса MAK-Logic, <br />
					ознакомьтесь с Политикой персональных данных, <br />
					с Соглашением о конфиденциальности, с Уведомлением <br />
					об обработке файлов cookie, с Условиями использования <br />
					сервиса и дайте свое согласие.
				</p>
				<div className="accept-terms__section-row">
					<div className="accept-terms__section-text">
						<Field name="is_accepted_personal_data_docs" type="checkbox" component={FormCheckboxField} />
					</div>
					<span className="accept-terms__section-field ">
						Я ознакомлен(а) с{' '}
						<a href={ORGANIZATION_DOCUMENTS.POLICY} target="_blank" rel="noopener noreferrer">
							Политикой{' '}
						</a>
						в отношении
						<br />
						персональных данных,{' '}
						<a href={ORGANIZATION_DOCUMENTS.AGREEMENT} target="_blank" rel="noopener noreferrer">
							Соглашением
						</a>{' '}
						<br /> о конфиденциальности и даю свое{' '}
						<span className="accept-terms__section-text-documents">согласие</span> <br /> на обработку
						персональных данных.
					</span>
				</div>
				<div className="accept-terms__section-row">
					<div className="accept-terms__section-text">
						<Field name="is_accepted_conditions_docs" type="checkbox" component={FormCheckboxField} />
					</div>
					<span className="accept-terms__section-field ">
						Я ознакомлен(а) с{' '}
						<a href={ORGANIZATION_DOCUMENTS.NOTIFICATION} target="_blank" rel="noopener noreferrer">
							Уведомлением
						</a>{' '}
						об обработке файлов <br />
						cookie и{' '}
						<a href={ORGANIZATION_DOCUMENTS.CONDITIONS} target="_blank" rel="noopener noreferrer">
							Условиями
						</a>{' '}
						использования сервиса MAK-Logic <br />и выражаю свое{' '}
						<span className="accept-terms__section-text-documents">согласие</span> с вышеуказанными <br />{' '}
						Условиями.
					</span>
				</div>
				<Button disabled={!isButtonEnabled} onClick={onButtonClick} className="accept-terms__button" primary>
					Подтверждаю
				</Button>
			</div>
		</div>
	);
};

AcceptTermsView.propTypes = {
	isButtonEnabled: PropTypes.bool.isRequired,
	onButtonClick: PropTypes.func.isRequired,
};

export { AcceptTermsView };
