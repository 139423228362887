/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getLocalStorageItem } from '../../utils/localStorage';
import { AcceptTermsView } from './accept-terms-view';
import { FORM_NAME } from './constants';
import { getIsButtonEnabledSelector } from './selectors';
import { acceptTermsAction } from './actions';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { getFromStorage } from '../../components/sidebar/utils';
import { ModalWindow } from '../../semantic-ui/components/modal-window';

const AcceptTermsWrapper = ({ isButtonEnabled, onAcceptTerms }) => {
	useInjectSaga({ key: 'acceptTermsPageSaga', saga });

	const history = useHistory();

	const user_data = getFromStorage('user_data');

	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		if (user_data.email_verified_at && !user_data.accepted_terms_at) {
			setModalShow(true);
		}
	}, [user_data]);

	useEffect(() => {
		const user_data = getLocalStorageItem('user_data');

		if (history.location.pathname.includes('/accept-terms') && user_data.accepted_terms_at) {
			history.push('/home/information');
		}
	}, []);

	const handleButtonClick = () => {
		onAcceptTerms(history.push);
	};

	return (
		<ModalWindow
			isModalShown={modalShow}
			modalContent={<AcceptTermsView isButtonEnabled={isButtonEnabled} onButtonClick={handleButtonClick} />}
			style={{ width: '595px' }}
		/>
	);
};

const AcceptTermsWithForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
})(AcceptTermsWrapper);

const mapStateToProps = createStructuredSelector({
	isButtonEnabled: getIsButtonEnabledSelector(),
});

const mapDispatchToProps = {
	onAcceptTerms: acceptTermsAction,
};

AcceptTermsWrapper.propTypes = {
	isButtonEnabled: PropTypes.bool.isRequired,
	onAcceptTerms: PropTypes.func.isRequired,
};

const AcceptTerms = connect(mapStateToProps, mapDispatchToProps)(AcceptTermsWithForm);

export { AcceptTerms };
