import React from 'react';
import { Field } from 'redux-form';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { FormDoublePhoneField } from '../../../../../semantic-ui/components/form-double-phone-field';
import { normalizePhone } from '../../../utils';

export const ContactModalView = () => {
	return (
		<div className="contact-form">
			<section className="contact-form__section">
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Фамилия</span>
					<div className="contact-form__field">
						<Field
							name="last_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите фамилию"
						/>
					</div>
				</div>
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Имя</span>
					<div className="contact-form__field">
						<Field
							name="first_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите имя"
						/>
					</div>
				</div>
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Отчество</span>
					<div className="contact-form__field">
						<Field
							name="middle_name"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите отчество"
						/>
					</div>
				</div>
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Почта</span>
					<div className="contact-form__field">
						<Field
							name="email"
							type="text"
							component={FormInputField}
							autoComplete="off"
							placeholder="Укажите адрес электронной почты"
						/>
					</div>
				</div>
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Телефон</span>
					<div className="contact-form__field">
						<FormDoublePhoneField
							mainFieldName="phone"
							additionalFieldName="extension"
							mainPlaceholder="+7 (___) ___-__-__"
							additionalPlaceholder="Добавочный"
							mainNormalize={normalizePhone}
						/>
					</div>
				</div>
				<div className="contact-form__row">
					<span className="contact-form__text contact-form__text_right">Использовать по умолчанию</span>
					<div className="contact-form__field">
						<Field name="is_default" component={FormCheckboxField} toggle type="checkbox" />
					</div>
				</div>
			</section>
		</div>
	);
};
