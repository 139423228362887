import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { NAV_ITEMS } from './constants';

export const selectInformationContainerDomain = state => state.informationContainerReducer || initialState;

export const getIsInformationLoadingSelector = () =>
	createSelector(selectInformationContainerDomain, ({ isInformationLoading }) => isInformationLoading);

export const getInformationSelector = () =>
	createSelector(selectInformationContainerDomain, ({ information }) => information);

export const getReqInfoSelector = () =>
	createSelector(selectInformationContainerDomain, ({ information }) => {
		return information?.reqs || {};
	});

export const getAccountsInfoSelector = () =>
	createSelector(selectInformationContainerDomain, ({ information }) => {
		return information?.accounts || [];
	});

export const getActiveNavItemSelector = () =>
	createSelector(selectInformationContainerDomain, ({ activeNavItem }) => {
		return activeNavItem;
	});

export const getActiveToDoSelector = () =>
	createSelector(selectInformationContainerDomain, ({ information, activeNavItem }) => {
		return information?.todo?.[NAV_ITEMS[activeNavItem]] || [];
	});

export const getIsInfoCompanyCheckedSelector = () =>
	createSelector(selectInformationContainerDomain, ({ isInfoCompanyChecked }) => isInfoCompanyChecked);
export const getBadgesSelector = () =>
	createSelector(selectInformationContainerDomain, ({ information }) => {
		return information?.badges || [];
	});
