import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Field } from 'redux-form';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { FormDoublePhoneField } from '../../../../../semantic-ui/components/form-double-phone-field';
import { normalizePhone } from '../../../utils';

export const ContactFormView = ({ isContactInfoLoading }) => {
	return (
		<div className="contact-form">
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Фамилия</span>
				<div className="contact-form__field">
					<Field
						name="last_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите фамилию"
						loading={isContactInfoLoading}
					/>
				</div>
			</div>
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Имя</span>
				<div className="contact-form__field">
					<Field
						name="first_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите Имя"
						loading={isContactInfoLoading}
					/>
				</div>
			</div>
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Отчество</span>
				<div className="contact-form__field">
					<Field
						name="middle_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите отчество"
						loading={isContactInfoLoading}
					/>
				</div>
			</div>
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Почта</span>
				<div className="contact-form__field">
					<Field
						name="email"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите адрес электронной почты"
						loading={isContactInfoLoading}
					/>
				</div>
			</div>
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Телефон</span>
				<div className="contact-form__field">
					<FormDoublePhoneField
						mainFieldName="phone"
						additionalFieldName="extension"
						mainPlaceholder="+7 (___) ___-__-__"
						additionalPlaceholder="Добавочный"
						mainNormalize={normalizePhone}
						isMainLoading={isContactInfoLoading}
						isAdditionalLoading={isContactInfoLoading}
					/>
				</div>
			</div>
			<div className="contact-form__row">
				<span className="contact-form__text contact-form__text_right">Использовать по умолчанию</span>
				<div className="contact-form__field">
					<Field name="is_default" component={FormCheckboxField} toggle type="checkbox" />
				</div>
			</div>
		</div>
	);
};

ContactFormView.propTypes = {
	isContactInfoLoading: PropTypes.bool.isRequired,
};
