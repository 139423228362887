import { CustomAccreditationCell } from './components/accreditation-custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const getAccreditationTableRows = (type, typeId) => {
	const baseColumns = [
		{
			title: '#',
			dataIndex: 'id',
			width: 50,
			minWidth: 50,
			align: 'center',
			render: (_, rowData) => CustomAccreditationCell({ rowData, columnId: 'id' }),
		},
		{ title: 'Компания', dataIndex: 'company_name', width: '150px' },
		{ title: 'Аккредитована', dataIndex: 'is_accredited', width: '120px' },
	];

	if ((type === 'trailers' && typeId === 1) || (type === 'cars' && typeId === 2)) {
		baseColumns.push(
			{ title: 'Одобренные t-режимы', dataIndex: 'approved_modes', width: '120px' },
			{ title: 'Не одобренные t-режимы', dataIndex: 'unapproved_modes', width: '120px' },
		);
	}

	return baseColumns;
};
