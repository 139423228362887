import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { FIELDS_FOR_VALIDATE, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import {
	CREATE_USER_ROLE,
	DELETE_USER_ROLE,
	GET_RULES,
	GET_USER_ROLE_INFO,
	setIsRulesLoadingAction,
	setIsUserRoleDeletingAction,
	setIsUserRoleInfoLoadingAction,
	setIsUserRoleSavingAction,
	setRulesAction,
	setUserRoleInfoAction,
	UPDATE_USER_ROLE,
} from './actions';
import {
	createUserRoleRequest,
	deleteUserRoleRequest,
	getRoutesRequest,
	getUserRoleRequest,
	updateUserRoleRequest,
} from '../../api/requests';
import { getDataForCreateOrUpdateUserRoleSelector, getFormIsInvalidSelector } from './selectors';
import { getRawPaginationSelector, getUsersRolesListSelector } from '../users-roles/selectors';
import { getUsersRolesListAction, setPaginationAction, setUsersRolesListAction } from '../users-roles/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getUserRoleInfoSaga({ id }) {
	try {
		yield put(setIsUserRoleInfoLoadingAction(true));

		const { data, message, toast } = yield call(getUserRoleRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setUserRoleInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserRoleInfoLoadingAction(false));
	}
}

export function* deleteUserRoleSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsUserRoleDeletingAction(true));

		const { data, message, toast } = yield call(deleteUserRoleRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const usersRoles = yield select(getUsersRolesListSelector());
			yield put(setUsersRolesListAction(usersRoles.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getUsersRolesListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserRoleDeletingAction(false));
	}
}

export function* createUserRoleSaga({ payload: { redirect, resetState, searchQuery } }) {
	try {
		yield put(setIsUserRoleSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateUserRoleSelector());

		const { data, errors, message, toast } = yield call(createUserRoleRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			redirect();
			resetState();
			yield put(
				getUsersRolesListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserRoleSavingAction(false));
	}
}

export function* updateUserRoleSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsUserRoleSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateUserRoleSelector());

		const { data, errors, message, toast } = yield call(updateUserRoleRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const usersRoles = yield select(getUsersRolesListSelector());

			const updatedUserRole = {
				...data,
				link: `/users_roles?${searchQuery ? `${searchQuery}&` : ''}modal=user_role&id=${data.id}`,
			};

			yield put(
				setUsersRolesListAction(
					usersRoles.map(role => {
						return role.id === data.id ? updatedUserRole : role;
					}),
				),
			);

			yield put(setUserRoleInfoAction(data));
			redirect();
			yield put(
				getUsersRolesListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserRoleSavingAction(false));
	}
}

export function* getRulesSaga() {
	try {
		yield put(setIsRulesLoadingAction(true));

		const { data, message, toast } = yield call(getRoutesRequest);
		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setRulesAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRulesLoadingAction(false));
	}
}

export default function* usersRolesModalSaga() {
	yield takeEvery(GET_USER_ROLE_INFO, getUserRoleInfoSaga);
	yield takeEvery(DELETE_USER_ROLE, deleteUserRoleSaga);
	yield takeEvery(CREATE_USER_ROLE, createUserRoleSaga);
	yield takeEvery(UPDATE_USER_ROLE, updateUserRoleSaga);
	yield takeLatest(GET_RULES, getRulesSaga);
}
