export const SET_PAGINATION = 'relationship_settings/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const SET_IS_RELATIONSHIP_LIST_LOADING = 'relationship_settings/SET_IS_RELATIONSHIP_LIST_LOADING';
export const setIsRelationshipListLoadingAction = payload => ({
	type: SET_IS_RELATIONSHIP_LIST_LOADING,
	payload,
});
export const SET_TABLE_SEARCH = 'relationship_settings/SET_TABLE_SEARCH';
export const setTableSearchAction = payload => ({
	type: SET_TABLE_SEARCH,
	payload,
});

export const RESET_STATE = 'relationship_settings/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
export const SET_IS_RELATIONSHIP_SETTINGS_LIST_LOADING =
	'relationship_settings/SET_IS_RELATIONSHIP_SETTINGS_LIST_LOADING';
export const setIsRelationshipSettingsListLoadingAction = payload => ({
	type: SET_IS_RELATIONSHIP_SETTINGS_LIST_LOADING,
	payload,
});
export const GET_RELATIONSHIP_SETTINGS_LIST = 'relationship_settings/GET_RELATIONSHIP_SETTINGS_LIST';
export const getRelationshipSettingsListAction = payload => ({
	type: GET_RELATIONSHIP_SETTINGS_LIST,
	payload,
});

export const SET_RELATIONSHIP_SETTINGS_LIST = 'relationship_settings/SET_RELATIONSHIP_SETTINGS_LIST';
export const setRelationshipSettingsListAction = payload => ({
	type: SET_RELATIONSHIP_SETTINGS_LIST,
	payload,
});
