import React from 'react';
import PropTypes from 'prop-types';
import { RelationshipSettingsForm } from '../relationship-settings-form';
import RelationshipSettingView from '../relationship-settings-view';

export const RelationshipSettingsContent = ({
	isRelationshipSettingInfoLoading,
	isRelationshipSettingAddFormOpen,
	stages,
	documentTypes,
	isStagesLoading,
	isDocumentsTypesLoading,
	formValues,
	relationshipSettings,
	change,
}) => {
	return isRelationshipSettingAddFormOpen ? (
		<RelationshipSettingsForm
			stages={stages}
			documentTypes={documentTypes}
			isDocumentsTypesLoading={isDocumentsTypesLoading}
			isStagesLoading={isStagesLoading}
			formValues={formValues}
			relationshipSettings={relationshipSettings}
			change={change}
		/>
	) : (
		<RelationshipSettingView
			relationshipSettings={relationshipSettings}
			isRelationshipSettingInfoLoading={isRelationshipSettingInfoLoading}
			formValues={formValues}
		/>
	);
};

RelationshipSettingsContent.propTypes = {
	relationshipSettings: PropTypes.object,
	isRelationshipSettingInfoLoading: PropTypes.bool.isRequired,
	isRelationshipSettingAddFormOpen: PropTypes.bool.isRequired,
	stages: PropTypes.array,
	documentTypes: PropTypes.array,
	isDocumentsTypesLoading: PropTypes.bool.isRequired,
	isStagesLoading: PropTypes.bool.isRequired,
	formValues: PropTypes.object,
	initialValues: PropTypes.array,
	change: PropTypes.func.isRequired,
};
