import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader } from 'semantic-ui-react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormCheckboxField } from '../../../../../semantic-ui/components/form-checkbox-field';
import { DocumentsComponent } from '../../documents';

const RelationshipSettingsFormView = ({
	isDocumentsTypesLoading,
	isStagesLoading,
	documentTypes,
	stages,
	change,
	formValues,
}) => {
	useEffect(() => {
		if (formValues?.stages?.done === false) {
			change('documents', []);
		}
	}, [formValues?.stages?.done, change]);
	return (
		<Grid className="create-setting-form">
			{isDocumentsTypesLoading || isStagesLoading ? (
				<Grid.Row className="create-setting-form__loader">
					<Loader active />
				</Grid.Row>
			) : (
				<>
					<Grid.Row className="create-setting-form__row">
						<Grid.Column width={6} className="create-setting-form__column">
							Название
						</Grid.Column>
						<Grid.Column width={8} className="create-setting-form__field">
							<Field
								name="name"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Укажите название настройки"
							/>
						</Grid.Column>
					</Grid.Row>
					{!isStagesLoading && (
						<Grid.Row className="create-setting-form__row">
							<Grid.Column className="create-setting-form__text">Жизненный цикл заявки</Grid.Column>
						</Grid.Row>
					)}
					{!isStagesLoading &&
						stages?.map(el => (
							<Grid.Row className="create-setting-form__row" key={el.key}>
								<Grid.Column width={6} className="create-setting-form__column">
									{el.name}
								</Grid.Column>
								<Grid.Column width={8} className="create-setting-form__field">
									<div className="create-setting-form__checkbox">
										<Field
											name={`stages.${el.key}`}
											component={FormCheckboxField}
											toggle
											type="checkbox"
											disabled={!el.editable}
										/>
									</div>
								</Grid.Column>
							</Grid.Row>
						))}

					{formValues?.stages?.done && (
						<FieldArray
							name="documents"
							component={DocumentsComponent}
							props={{ documentTypes, change, formValues }}
						/>
					)}
				</>
			)}
		</Grid>
	);
};

RelationshipSettingsFormView.propTypes = {
	isDocumentsTypesLoading: PropTypes.bool.isRequired,
	isStagesLoading: PropTypes.bool.isRequired,
	stages: PropTypes.array.isRequired,
	documentTypes: PropTypes.array.isRequired,
	change: PropTypes.func.isRequired,
	formValues: PropTypes.object,
};

export { RelationshipSettingsFormView };
