import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUIRED_FIELDS, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import {
	CREATE_WAY,
	DELETE_WAY,
	GET_WAY_INFO,
	setWayInfoAction,
	setIsWayDeletingAction,
	setIsWayInfoLoadingAction,
	setIsWaySavingAction,
	UPDATE_WAY,
	GET_ADDRESSES,
	setAddressesAction,
	setAddressesSearchValueAction,
	GET_MODES,
	setModesAction,
	setIsModesLoadingAction,
	setIsLoadingAddressesAction,
} from './actions';
import { getAddressesBySearchRequest, getModesDictionaryRequest } from '../../api/requests';
import { createWayRequest, deleteWayRequest, getWayInfoRequest, updateWayRequest } from '../../api/ways-requests';
import { getAddressesSelector, getDataForUpdateSelector, getFormIsInvalidSelector } from './selectors';
import { getRawPaginationSelector, getWaysListSelector } from '../ways/selectors';
import { getWaysListAction, setPaginationAction, setWaysListAction } from '../ways/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getWayInfoSaga({ id }) {
	try {
		yield put(setIsWayInfoLoadingAction(true));

		const { data, message, toast } = yield call(getWayInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			// set search values to initial form values
			const addressSearchValues = {};
			addressSearchValues.address_from = data.points[0].address;
			data.points.slice(1).forEach((point, index) => {
				addressSearchValues[`address_to[${index}].address`] = point.address;
			});
			yield put(setAddressesSearchValueAction(addressSearchValues));

			yield put(setWayInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsWayInfoLoadingAction(false));
	}
}

export function* deleteWaySaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsWayDeletingAction(true));

		const { data, message, toast } = yield call(deleteWayRequest, id);

		if (data) {
			// remove deleted from list
			const waysList = yield select(getWaysListSelector());
			yield put(setWaysListAction(waysList.filter(way => way.id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			redirect();
			yield put(getWaysListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsWayDeletingAction(false));
	}
}

export function* createWaySaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsWaySavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForUpdateSelector());

		const { data, errors, message, toast } = yield call(createWayRequest, dataForSave);

		if (data) {
			// add data to list
			const waysList = yield select(getWaysListSelector());
			yield put(
				setWaysListAction([
					{
						...data,
						address_from: data.points[0].address,
						address_to: data.points[1].address,
						date_from: data.points[0].date,
						date_to: data.points[1].date,
						link: `/home/ways?modal=way&id=${data.id}`,
					},
					...waysList,
				]),
			);

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			redirect();
			yield put(getWaysListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			yield put(touch(FORM_NAME, ...REQUIRED_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsWaySavingAction(false));
	}
}

export function* updateWaySaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsWaySavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForUpdateSelector());

		const { data, errors, message, toast } = yield call(updateWayRequest, id, dataForSave);
		if (data) {
			// update data in list
			const waysList = yield select(getWaysListSelector());

			const updatedDetail = {
				...data,
				link: `/home/ways?modal=way&id=${data.id}`,
				address_from: data.points[0].address,
				date_from: data.points[0].date,
				address_to: data.points[data.points.length - 1].address,
				date_to: data.points[data.points.length - 1].date,
			};

			yield put(
				setWaysListAction(
					waysList.map(way => {
						return way.id === data.id
							? updatedDetail
							: { ...way, is_default: data.is_default ? false : way.is_default };
					}),
				),
			);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			yield put(setWayInfoAction(data));
			redirect();
			yield put(getWaysListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			yield put(touch(FORM_NAME, ...REQUIRED_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsWaySavingAction(false));
	}
}

export function* getAddressesSaga({ payload: { searchValue, name } }) {
	try {
		yield put(setIsLoadingAddressesAction({ [name]: true }));

		const data = yield call(getAddressesBySearchRequest, searchValue);

		if (data) {
			const addresses = yield select(getAddressesSelector());

			yield put(setAddressesAction({ ...addresses, [name]: data.suggestions }));
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsLoadingAddressesAction({ [name]: false }));
	}
}

export function* getModesSaga() {
	try {
		yield put(setIsModesLoadingAction(true));

		const { data, message } = yield call(getModesDictionaryRequest, '?receive=all');

		if (data) {
			yield put(setModesAction(data.reverse()));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsModesLoadingAction(false));
	}
}

export default function* trailersContainerSaga() {
	yield takeEvery(GET_WAY_INFO, getWayInfoSaga);
	yield takeEvery(DELETE_WAY, deleteWaySaga);
	yield takeEvery(CREATE_WAY, createWaySaga);
	yield takeEvery(UPDATE_WAY, updateWaySaga);
	yield takeEvery(GET_MODES, getModesSaga);
	yield debounce(1000, GET_ADDRESSES, getAddressesSaga);
}
