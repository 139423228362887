import { RESET_STATE, SET_ACCREDITATION_DATA, SET_IS_ACCREDITATION_DATA_LOADING } from './actions';

export const initialState = {
	accreditationData: null,
	isAccreditationDataLoading: false,
};

const accreditationComponentReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_ACCREDITATION_DATA:
			return {
				...state,
				accreditationData: payload,
			};
		case SET_IS_ACCREDITATION_DATA_LOADING:
			return {
				...state,
				isAccreditationDataLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default accreditationComponentReducer;
