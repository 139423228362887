import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import {
	GET_TRAILERS_LIST,
	setIsTrailersListLoadingAction,
	setPaginationAction,
	setTrailersListAction,
} from './actions';
import { getTrailersListRequest } from '../../api/requests';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';

export function* getTrailersListSaga({ payload: { searchQuery, noLoading } }) {
	try {
		if (!noLoading) {
			yield put(setIsTrailersListLoadingAction(true));
		}
		const { data, meta, message, toast } = yield call(getTrailersListRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTrailersListAction(
					data?.map(trailer => ({
						...trailer,
						type_name: trailer.type?.name ?? '---',
						link: `/home/trailers?${searchQuery ? `${searchQuery}&` : ''}modal=trailer&id=${trailer.id}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailersListLoadingAction(false));
	}
}

export default function* trailersContainerSaga() {
	yield takeEvery(GET_TRAILERS_LIST, getTrailersListSaga);
}
