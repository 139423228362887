import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { PARTNER_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import {
	createPartnerRequest,
	deletePartnerRequest,
	getPartnerInfoRequest,
	getPartnersBySearchRequest,
	updatePartnerRequest,
} from '../../api/partners-requests';
import {
	CREATE_PARTNER,
	DELETE_PARTNER,
	GET_PARTNERS_OPTIONS,
	GET_PARTNER_INFO,
	UPDATE_PARTNER,
	setPartnerInfoAction,
	setPartnersOptionsAction,
	setIsPartnerDeletingAction,
	setIsPartnerInfoLoadingAction,
	setIsPartnerUpdatingAction,
	setIsPartnersOptionsLoadingAction,
	resetStateAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import { getPartnerFormValuesSelector, getPartnerSearchValueSelector, getFormIsInvalidSelector } from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { getPartnersSelector, getRawPaginationSelector } from '../partners/selectors';
import { getPartnersAction, setPaginationAction, setPartnersAction } from '../partners/actions';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { errorHandler } from '../../api/utils';
import { getFromStorage } from '../../components/sidebar/utils';

export function* getPartnerInfoSaga({ payload: id }) {
	try {
		yield put(setIsPartnerInfoLoadingAction(true));

		const { data, message, toast } = yield call(getPartnerInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setPartnerInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnerInfoLoadingAction(false));
	}
}

export function* createPartnerSaga({ payload: { redirect, searchQuery } }) {
	try {
		yield put(setIsPartnerUpdatingAction(true));

		const dataForSave = yield select(getPartnerFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createPartnerRequest, dataForSave);

		if (data) {
			const str = removeModalQuery(searchQuery);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const rules = getFromStorage('rules');

			const query = new URLSearchParams(searchQuery);

			yield put(getPartnersAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));

			if (rules.addresses?.create) {
				query.set('modal', 'address');

				redirect(`/home/partners?${query.toString()}&id=${data.id}`);
			} else {
				query.delete('modal');
				query.delete('mode');

				yield put(resetStateAction());
				redirect(`/home/partners?${query.toString()}`);
			}
		} else {
			if (errors) {
				yield put(touch(PARTNER_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(PARTNER_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnerUpdatingAction(false));
	}
}

export function* updatePartnerSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsPartnerUpdatingAction(true));

		const dataForSave = yield select(getPartnerFormValuesSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(updatePartnerRequest, id, dataForSave);

		if (data) {
			const str = removeModalQuery(searchQuery);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const partners = yield select(getPartnersSelector());

			const updatedPartner = {
				...data,
				link: `/home/partners?${str ? `${str}&` : ''}modal=partner&id=${data.id}`,
			};

			yield put(
				setPartnersAction(
					partners.map(partner => {
						return partner.id === data.id ? updatedPartner : partner;
					}),
				),
			);

			yield put(setPartnerInfoAction(data));

			const query = new URLSearchParams(searchQuery);
			query.delete('mode');

			redirect(`/home/partners?${query.toString()}`);
			yield put(getPartnersAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			if (errors) {
				yield put(touch(PARTNER_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(PARTNER_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnerUpdatingAction(false));
	}
}

export function* deletePartnerSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsPartnerDeletingAction(true));

		const { data, message, toast } = yield call(deletePartnerRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const partners = yield select(getPartnersSelector());

			yield put(setPartnersAction(partners.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getPartnersAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnerDeletingAction(false));
	}
}

export function* getPartnersSaga() {
	try {
		yield put(setIsPartnersOptionsLoadingAction(true));

		const searchQuery = yield select(getPartnerSearchValueSelector());

		const response = yield call(getPartnersBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setPartnersOptionsAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnersOptionsLoadingAction(false));
	}
}

export default function* partnerModalPageSaga() {
	yield takeEvery(GET_PARTNER_INFO, getPartnerInfoSaga);
	yield takeEvery(CREATE_PARTNER, createPartnerSaga);
	yield takeEvery(UPDATE_PARTNER, updatePartnerSaga);
	yield takeEvery(DELETE_PARTNER, deletePartnerSaga);
	yield debounce(300, GET_PARTNERS_OPTIONS, getPartnersSaga);
}
