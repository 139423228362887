import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../../semantic-ui/components/form-select-field';
import { CustomDatePicker } from '../../../../../semantic-ui/components/custom-datepicker';
import { normalizeNumber } from '../../../../../utils/normalizeNumber';
import { PercentIcon } from '../../../../../components/text-icon';
import AddButton from '../../../../../components/images/add-button.svg';

export const RelationshipFormView = ({ optionsList, isOptionsListLoading, isRelationshipInfoLoading, isAdmin }) => {
	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const relationshipId = query.get('id');

	const currentModal = query.get('modal');

	const handleAddButtonClick = () => {
		if (currentModal) {
			query.set('fromModal', `${currentModal}-${query.get('mode') || ''}`);
		}
		query.set('modal', 'relationship_settings');
		query.set('mode', 'add');
		if (relationshipId) {
			query.set('relationshipId', relationshipId);
		}
		history.push(`${pathname}?${query}`);
	};

	return (
		<Grid className="relationship-form">
			<Grid.Row className="relationship-form__row">
				<Grid.Column width={6} className="relationship-form__column">
					Заказчик
				</Grid.Column>
				<Grid.Column width={8} className="relationship-form__column">
					<Field
						name="shipper"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={optionsList.shippers}
						loading={isOptionsListLoading.shippers}
						placeholder="Выберите заказчика"
						isWithoutSelectOnBlur
						search
						disabled={!isAdmin}
						dropdownClassName="static"
						noResultsMessage="Не найдено"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="relationship-form__row">
				<Grid.Column width={6} className="relationship-form__column">
					Транспортная компания
				</Grid.Column>
				<Grid.Column width={8} className="relationship-form__column">
					<Field
						name="carrier"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={optionsList.carriers}
						loading={isOptionsListLoading.carriers}
						placeholder="Выберите транспортную компанию"
						isWithoutSelectOnBlur
						search
						dropdownClassName="static"
						noResultsMessage="Не найдено"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="relationship-form__row">
				<Grid.Column width={6} className="relationship-form__column">
					Договор №
				</Grid.Column>
				<Grid.Column width={8} className="relationship-form__column">
					<div className="relationship-form__contract-section">
						<div className="relationship-form__contract-section-title">
							<Field
								name="contract_number"
								type="text"
								component={FormInputField}
								autoComplete="off"
								placeholder="Укажите номер договора"
								loading={isRelationshipInfoLoading}
							/>
						</div>
						<span className="relationship-form__contract-section-from">от</span>
						<div className="relationship-form__contract-section-date">
							<Field
								name="contract_date"
								type="text"
								component={CustomDatePicker}
								autoComplete="off"
								placeholder=". . / . . / . ."
								dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								loading={isRelationshipInfoLoading}
							/>
						</div>
					</div>
				</Grid.Column>
			</Grid.Row>
			{isAdmin && (
				<>
					<Grid.Row className="relationship-form__row">
						<Grid.Column width={6} className="relationship-form__column">
							Страхование
						</Grid.Column>
						<Grid.Column width={4} className="relationship-form__column">
							<Field
								name="insurance"
								type="text"
								normalize={normalizeNumber}
								component={FormInputField}
								autoComplete="off"
								icon={PercentIcon}
								placeholder="Страхование"
								loading={isRelationshipInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="relationship-form__row">
						<Grid.Column width={6} className="relationship-form__column">
							Комиссия
						</Grid.Column>
						<Grid.Column width={4} className="relationship-form__column">
							<Field
								name="fee"
								type="text"
								normalize={normalizeNumber}
								component={FormInputField}
								autoComplete="off"
								icon={PercentIcon}
								placeholder="Комиссия"
								loading={isRelationshipInfoLoading}
							/>
						</Grid.Column>
					</Grid.Row>
				</>
			)}
			<Grid.Row className="relationship-form__row-text">Настройка жизненного цикла заявки</Grid.Row>

			{optionsList.categories.map(el => (
				<Grid.Row key={el.key} className="relationship-form__row">
					<Grid.Column width={6} className="relationship-form__column">
						{el.text}
					</Grid.Column>
					<Grid.Column width={8} className="relationship-form__column">
						<Field
							name={`settings ${el.value}`}
							type="text"
							component={FormSelectField}
							autoComplete="off"
							options={optionsList.settings}
							loading={isOptionsListLoading.carriers}
							placeholder="Выберите настройку"
							isWithoutSelectOnBlur
							search
							dropdownClassName="static"
							noResultsMessage="Не найдено"
						/>
					</Grid.Column>
					<Grid.Column
						width={1}
						style={{ display: 'flex', alignItems: 'flex-end' }}
						className="relationship-form__button"
					>
						<div className="relationship-form__button-row">
							<button
								type="button"
								onClick={handleAddButtonClick}
								style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
							>
								<img src={AddButton} alt="Добавить настройки" />
							</button>
						</div>
					</Grid.Column>
				</Grid.Row>
			))}
		</Grid>
	);
};

RelationshipFormView.propTypes = {
	optionsList: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
				key: PropTypes.string.isRequired,
			}),
		),
	),
	isOptionsListLoading: PropTypes.objectOf(PropTypes.bool.isRequired).isRequired,
	isRelationshipInfoLoading: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
};
