/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { AddressModalContent } from '../address-modal-content';
import {
	createAddressAction,
	deleteAddressAction,
	getAddressInfoAction,
	resetStateAction,
	setAddressesOptionsAction,
	setAddressSearchValueAction,
	updateAddressAction,
} from '../../actions';
import {
	getActiveAddressIdSelector,
	getAddressInfoSelector,
	getAddressNameSelector,
	getAddressSelector,
	getIsAddressAddFormOpenSelector,
	getIsAddressEditFormOpenSelector,
	getIsAddressInfoLoadingSelector,
	getIsAuditTabShownSelector,
	getIsModalShownSelector,
} from '../../selectors';
import { ADDRESSES_FORM_NAME } from '../../constants';
import { resetStateAction as resetContactModalState } from '../../../contact-modal/actions';
import {
	getIsAvailableAddressesButtonsSelector,
	getIsAvailableContactsSectionSelector,
} from '../../../profile/selectors';
import { setWindowInfoAction } from '../../../alert-modal/actions';
import { Audit } from '../../../../components/audit';

const AddressModalWrapper = ({
	onGetAddressInfo,
	isAddressInfoLoading,
	isAddressEditFormOpen,
	isAddressAddFormOpen,
	addressInfo,
	addressName,
	onResetState,
	onResetContactModalState,
	onDeleteAddress,
	isModalShown,
	activeAddressId,
	onCreateAddress,
	onUpdateAddress,
	onFormSubmit,
	onSetAddressSearchValue,
	onSetAddressesOptions,
	address,
	isAvailable,
	isContactsAvailable,
	onSetWindowInfo,
	isAuditTabShown,
}) => {
	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const modal = query.get('modal');

	const mode = query.get('mode');

	useEffect(() => {
		if (activeAddressId) onGetAddressInfo(activeAddressId);
	}, [activeAddressId]);

	const handleAddressFormOpenButtonClick = mode => {
		if (activeAddressId) {
			query.set('id', activeAddressId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
			onSetAddressSearchValue(address);
		}

		if (mode === 'cancel') {
			onSetAddressSearchValue('');
			onSetAddressesOptions([]);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeAddressId) {
			onResetState();
			onResetContactModalState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('addressId');
		query.delete('tab');

		onResetState();
		onResetContactModalState();
		history.push(`${pathname}?${query}`);
	};

	const handleAddressDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteAddress({ id: activeAddressId, redirect: handleModalClose, query }),
			},
		});
	};

	const handleAddressFormSubmit = async () => {
		await onFormSubmit(ADDRESSES_FORM_NAME);

		if (activeAddressId) {
			onUpdateAddress({ id: activeAddressId, searchQuery: query.toString(), redirect: history.push });

			return;
		}

		onCreateAddress({ searchQuery: query.toString(), redirect: history.push });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleAddressFormSubmit();
		}
	};

	const handleNavItemClick = modal => {
		query.delete('tab');
		query.set('modal', modal === 'audit' ? 'address' : modal);

		if (mode) {
			query.delete('mode');
		}

		if (modal === 'address') {
			onResetContactModalState();
		}

		if (modal === 'contacts') {
			onResetState();
		}

		if (modal === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isAddressEditFormOpen || isAddressAddFormOpen
					? `${isAddressEditFormOpen ? 'Изменить адрес' : 'Создать адрес'}`
					: addressName
			}
			navItems={
				isAddressAddFormOpen || isAddressEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('address'),
								isActive: modal === 'address' && !mode && !isAuditTabShown,
							},
							...(isContactsAvailable
								? [
										{
											id: 'contacts',
											text: 'Контактные лица',
											onClick: () => handleNavItemClick('contacts'),
											isActive: modal === 'contacts',
										},
								  ]
								: []),
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: modal === 'address' && !mode && isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<AddressModalContent
						addressInfo={addressInfo}
						isAddressInfoLoading={isAddressInfoLoading}
						isAddressFormOpen={isAddressEditFormOpen || isAddressAddFormOpen}
					/>
				) : (
					<Audit entityName="addresses" entityId={activeAddressId} />
				)
			}
			style={{ width: '900px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'profile' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isAddressEditFormOpen || isAddressAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleAddressDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isAddressInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isAddressEditFormOpen || isAddressAddFormOpen
							? [
									{
										onClick: handleAddressFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isAddressInfoLoading,
									},
									{
										onClick: () => handleAddressFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isAddressInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleAddressFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isAddressInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	addressInfo: getAddressInfoSelector(),
	isAddressInfoLoading: getIsAddressInfoLoadingSelector(),
	isAddressAddFormOpen: getIsAddressAddFormOpenSelector(),
	isAddressEditFormOpen: getIsAddressEditFormOpenSelector(),
	addressName: getAddressNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeAddressId: getActiveAddressIdSelector(),
	address: getAddressSelector(),
	isAvailable: getIsAvailableAddressesButtonsSelector(),
	isContactsAvailable: getIsAvailableContactsSectionSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetAddressInfo: getAddressInfoAction,
	onDeleteAddress: deleteAddressAction,
	onResetState: resetStateAction,
	onResetContactModalState: resetContactModalState,
	onCreateAddress: createAddressAction,
	onUpdateAddress: updateAddressAction,
	onFormSubmit: submit,
	onSetAddressSearchValue: setAddressSearchValueAction,
	onSetAddressesOptions: setAddressesOptionsAction,
	onSetWindowInfo: setWindowInfoAction,
};

const AddressModal = connect(mapStateToProps, mapDispatchToProps)(AddressModalWrapper);

AddressModalWrapper.propTypes = {
	onGetAddressInfo: PropTypes.func.isRequired,
	addressInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isAddressInfoLoading: PropTypes.bool.isRequired,
	isAddressEditFormOpen: PropTypes.bool.isRequired,
	isAddressAddFormOpen: PropTypes.bool.isRequired,
	addressName: PropTypes.string.isRequired,
	onDeleteAddress: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	onResetContactModalState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeAddressId: PropTypes.string,
	onCreateAddress: PropTypes.func.isRequired,
	onUpdateAddress: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSetAddressSearchValue: PropTypes.func.isRequired,
	onSetAddressesOptions: PropTypes.func.isRequired,
	address: PropTypes.string.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	isContactsAvailable: PropTypes.bool,
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

export { AddressModal };
