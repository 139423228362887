import { CustomCell } from '../../components/custom-cell';
import { ToggleCell } from './components/toggle-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const CONTACTS_TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'ФИО',
		dataIndex: 'name',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }),
	},
	{ title: 'Почта', dataIndex: 'email' },
	{ title: 'Телефон', dataIndex: 'phone' },
	{
		title: 'Использовать по умолчанию',
		dataIndex: 'is_default',
		width: 200,
		render(_, rowData) {
			// eslint-disable-next-line react/react-in-jsx-scope
			return <ToggleCell rowData={rowData} columnId="is_default" />;
		},
	},
];
