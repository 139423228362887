import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { getMenuItems } from './utils';
import BUTTONS from './pages.json';
import { getRequestsClaimsCountSelector, getRequestsCountSelector } from '../../containers/profile/selectors';
import { getCountSelector } from '../../containers/reqs-list/selectors';
import { getClaimsCountSelector } from '../../containers/claims/selectors';

export const selectSidebarPageDomain = state => state.sidebarReducer || initialState;
export const selectRouterPageDomain = state => state.router || initialState;

export const getActiveSubmenusSelector = () => state =>
	state.sidebarReducer?.activeSubmenus || initialState.activeSubmenus;

export const getRouterLocationSelector = () => state => state.router?.location || initialState.location;

export const getMenuItemsSelector = () =>
	createSelector(
		getActiveSubmenusSelector(),
		getRouterLocationSelector(),
		getRequestsCountSelector(),
		getCountSelector(),
		getClaimsCountSelector(),
		getRequestsClaimsCountSelector(),
		(activeSubmenus, location, profileReqsCount, reqsCount, claimsCount, profileClaimsCount) => {
			// если открыть страницу не на вкладке "заявки", то берем количество из профиля
			const reqsTotalCount = reqsCount?.total || profileReqsCount;

			const reqsCategoryCount = reqsCount;

			const claimCount = claimsCount?.total || profileClaimsCount;

			return getMenuItems(
				BUTTONS,
				location.pathname,
				activeSubmenus,
				reqsTotalCount,
				claimCount,
				reqsCategoryCount,
			);
		},
	);

export const getIsUserPermissionsLoadingSelector = () => state =>
	state.sidebarReducer?.isUserPermissionsLoading || initialState.isUserPermissionsLoading;

export const getIsSubmenuOpenSelector = () =>
	createSelector(
		getActiveSubmenusSelector(),
		getIsUserPermissionsLoadingSelector(),
		(activeSubmenus, isUserPermissionsLoading) => activeSubmenus.length > 0 && !isUserPermissionsLoading,
	);
