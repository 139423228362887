import { CustomCell } from './components/custom-claim-cell/index';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Дата',
		dataIndex: 'created_at',
		width: 100,
		formatter: value => new Date(value).toLocaleDateString('ru-RU'),
	},
	{
		title: 'Заявка',
		dataIndex: 'request_id',
		width: 80,
		render: (_, rowData) => CustomCell({ rowData, columnId: 'request_id' }),
	},
	{ title: 'Раздел', dataIndex: 'request_category' },
	{ title: 'Категория', dataIndex: 'category_name' },
	{ title: 'От кого', dataIndex: 'from_name' },
	{ title: 'К кому', dataIndex: 'to_name' },
	{
		title: 'Приоритет',
		dataIndex: 'priority_name',
		width: 150,
		render: (_, rowData) => CustomCell({ rowData, columnId: 'priority_name' }),
	},
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABS = [
	{ key: 'opened', text: 'Новые', href: '/claims/opened' },
	{ key: 'approve', text: 'Необходимо согласовать', href: '/claims/approve' },
	{ key: 'approval', text: 'На согласовании', href: '/claims/approval' },
	{ key: 'closed', text: 'Завершенные', href: '/claims/closed' },
];
