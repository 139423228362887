import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../../../semantic-ui/components/form-select-field';
import { FormSelect } from '../../../../../../semantic-ui/components/form-select';
import { FormDoublePhoneField } from '../../../../../../semantic-ui/components/form-double-phone-field';
import { normalizePhone } from '../../../utils';
import { CustomDatePicker } from '../../../../../../semantic-ui/components/custom-datepicker';
import { FormCheckboxField } from '../../../../../../semantic-ui/components/form-checkbox-field';

export const CompanyFormView = ({
	isCompanyInfoLoading,
	companiesTypes,
	isCompaniesTypesLoading,
	companiesOptions,
	onCompanySearchChange,
	onCompanyChange,
	companiesSearchValue,
	isCompaniesLoading,
	isProfileCompanyForm,
}) => {
	return (
		<Grid className="company-form">
			<Grid.Row className="company-form__row company-form__row-title">
				<div className="company-form__title">Компания</div>
				{!isProfileCompanyForm && (
					<>
						<div className="company-form__subtitle">
							Вы можете заполнить реквизиты вручную или выбрать из справочника
						</div>
					</>
				)}
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Тип компании
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="company.type_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={companiesTypes}
						search
						loading={isCompaniesTypesLoading}
						noResultsMessage="Не найдено"
						disabled={isProfileCompanyForm}
					/>
				</Grid.Column>
			</Grid.Row>
			{!isProfileCompanyForm && (
				<>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							<div className="company-form__column_text-bold">Справочник</div>
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<Field
								name="select"
								type="text"
								component={FormSelect}
								autoComplete="off"
								placeholder="Введите ИНН/ОГРН или название организации"
								icon="search"
								options={companiesOptions}
								loading={isCompaniesLoading}
								noResultsMessage="Неизвестная организация"
								onSearchChange={onCompanySearchChange}
								onItemChange={onCompanyChange}
								searchValue={companiesSearchValue}
								disabled={isProfileCompanyForm}
							/>
						</Grid.Column>
					</Grid.Row>
				</>
			)}
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Название
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="company.name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите название компании"
						loading={isCompanyInfoLoading}
						disabled={isProfileCompanyForm}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					ИНН
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="company.inn"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите ИНН"
						loading={isCompanyInfoLoading}
						disabled={isProfileCompanyForm}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					ОГРН
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="company.ogrn"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите ОГРН"
						loading={isCompanyInfoLoading}
						disabled={isProfileCompanyForm}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Юридический адрес огранизации
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="company.address"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите юридический адрес огранизации"
						loading={isCompanyInfoLoading}
						disabled={isProfileCompanyForm}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row company-form__row-title">
				<div className="company-form__second-title">Контактное лицо</div>
				<div className="company-form__second-subtitle">
					Укажите данные контактного лица компании,
					<br />с кем мы сможем связываться по вопросам регистрации, аккредитации, доставки и т.д
				</div>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Фамилия
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="contact.last_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите фамилию"
						loading={isCompanyInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Имя
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="contact.first_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите имя"
						loading={isCompanyInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Отчество
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="contact.middle_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите отчество"
						loading={isCompanyInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Почта
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<Field
						name="contact.email"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите адрес электронной почты"
						loading={isCompanyInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="company-form__row">
				<Grid.Column width={5} className="company-form__column">
					Телефон
				</Grid.Column>
				<Grid.Column width={8} className="company-form__column">
					<FormDoublePhoneField
						mainFieldName="contact.phone"
						mainPlaceholder="+7 (___) ___-__-__"
						additionalFieldName="contact.extension"
						mainNormalize={normalizePhone}
						additionalPlaceholder="Добавочный"
					/>
				</Grid.Column>
			</Grid.Row>
			{!isProfileCompanyForm && (
				<>
					<Grid.Row className="company-form__row company-form__row-title">
						<div className="company-form__second-title">Аккредитация</div>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Договор №
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<div className="company-form__column-with-multiple-fields">
								<div className="company-form__contract-field">
									<Field
										name="company.contract_number"
										type="text"
										component={FormInputField}
										autoComplete="off"
										placeholder="Укажите номер договора"
										loading={isCompanyInfoLoading}
									/>
								</div>
								от
								<Field
									name="company.contract_date"
									type="text"
									component={CustomDatePicker}
									autoComplete="off"
									placeholder=". . / . . / . ."
									dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Договор SaaS №
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<div className="company-form__column-with-multiple-fields">
								<div className="company-form__contract-field">
									<Field
										name="company.saas_number"
										type="text"
										component={FormInputField}
										autoComplete="off"
										placeholder="Укажите номер договора"
										loading={isCompanyInfoLoading}
									/>
								</div>
								от
								<Field
									name="company.saas_date"
									type="text"
									component={CustomDatePicker}
									autoComplete="off"
									placeholder=". . / . . / . ."
									dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Компания аккредитована
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<Field name="company.is_accredited" component={FormCheckboxField} toggle type="checkbox" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Компания прошла аудит
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<Field name="company.is_validated" component={FormCheckboxField} toggle type="checkbox" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Подписан договор c МАК-Лоджик
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<Field name="company.has_contract" component={FormCheckboxField} toggle type="checkbox" />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="company-form__row">
						<Grid.Column width={5} className="company-form__column">
							Подписан договор SaaS
						</Grid.Column>
						<Grid.Column width={8} className="company-form__column">
							<Field name="company.has_saas" component={FormCheckboxField} toggle type="checkbox" />
						</Grid.Column>
					</Grid.Row>
				</>
			)}
		</Grid>
	);
};

CompanyFormView.propTypes = {
	isCompaniesTypesLoading: PropTypes.bool.isRequired,
	isCompanyInfoLoading: PropTypes.bool.isRequired,
	companiesTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))),
	companiesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isCompaniesLoading: PropTypes.bool.isRequired,
	onCompanySearchChange: PropTypes.func.isRequired,
	onCompanyChange: PropTypes.func.isRequired,
	companiesSearchValue: PropTypes.string.isRequired,
	isProfileCompanyForm: PropTypes.bool.isRequired,
};
