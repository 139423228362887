import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { GET_ACCREDITATION_DATA, setAccreditationtDataAction, setIsAccreditationtDataLoadingAction } from './actions';
import { errorHandler } from '../../api/utils';
import { getAccreditationRequest } from '../../api/transport';

export function* getAccreditationDataSaga({ payload: { type, id } }) {
	try {
		yield put(setIsAccreditationtDataLoadingAction(true));

		const { message, toast, data } = yield call(getAccreditationRequest, type, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setAccreditationtDataAction(
					data?.map(data => ({
						...data,
						link: `/home/${type}?modal=company&id=${data.company.id}`,
					})),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAccreditationtDataLoadingAction(false));
	}
}

export default function* accreditationComponentSaga() {
	yield takeEvery(GET_ACCREDITATION_DATA, getAccreditationDataSaga);
}
