import { CustomCell } from '../../components/custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const PARTNERS_TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Название организации',
		dataIndex: 'name',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }),
	},
	{ title: 'ИНН', dataIndex: 'inn' },
	{ title: 'ОГРН', dataIndex: 'ogrn' },
];
