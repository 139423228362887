/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { getFormValues, reduxForm, reset, submit } from 'redux-form';
import {
	getIsModalShownSelector,
	getIsFormSendingSelector,
	getStagesSelector,
	getIsStagesLoadingSelector,
	getIsDocumentsTypesLoadingSelector,
	getDocumentTypesOptionsSelector,
	getIsRelationshipSettingAddFormOpenSelector,
	getRelationshipSettingHeaderSelector,
	getIsRelationshipSettingLoadingSelector,
	getRelationshipSettingInfoSelector,
	getIsAuditTabShownSelector,
	getActiveRelationshipSettingIdSelector,
	getRelationshipSettingTabsSelector,
	getFormInitialValuesSelector,
	getIsRelationshipSettingEditFormOpenSelector,
} from './selectors';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import {
	deleteRelationshipSettingsAction,
	getDocumentsTypesAction,
	getRelationshipSettingsAction,
	getStagesAction,
	resetStateAction,
	sendSettingsAction,
	setDocumentsTypesAction,
	setStagesAction,
	updateRelationshipSettingsAction,
} from './actions';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { Audit } from '../../components/audit';
import { RelationshipSettingsContent } from './components/relationship-settings-content';
import validate from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';
import { getIsAvailableRelationshipSettingsButtonsSelector } from '../profile/selectors';
import { getRelationshipInfoSelector } from '../relationship-modal/selectors';

const RelationshipSettingsModalWrapper = ({
	onClearForm,
	onGetDocumentTypes,
	onGetStages,
	isModalShown,
	isFormSending,
	stages,
	documentTypes,
	isStagesLoading,
	isDocumentsTypesLoading,
	onFormSubmit,
	onSendSettings,
	isRelationshipSettingAddFormOpen,
	relationshipSettings,
	relationshipSettingHeader,
	isRelationshipSettingInfoLoading,
	onGetRelationshipSetting,
	activeRelationshipSettingId,
	isAuditTabShown,
	relationshipSettingTabs,
	formValues,
	isAvailable,
	onSetWindowInfo,
	onResetState,
	onDeleteRelationshipSettings,
	isRelationshipSettingEditFormOpen,
	onUpdateRelationshipSettings,
	change,
	relationshipInfo,
}) => {
	if (!isModalShown) return null;

	useInjectSaga({ key: 'relationshipSettingsModalSaga', saga });
	useInjectReducer({ key: 'relationshipSettingsReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	const tab = query.get('tab');

	const mode = query.get('mode');

	const fromModal = query.get('fromModal');

	const filteredRelationshipInfo = relationshipInfo.find(el => el.id === 'id');

	const relationshipId = filteredRelationshipInfo ? filteredRelationshipInfo.value : null;

	useEffect(() => {
		if (isModalShown) {
			onGetStages();
			onGetDocumentTypes(8);
		}
	}, []);
	useEffect(() => {
		if (activeRelationshipSettingId) {
			onGetRelationshipSetting(activeRelationshipSettingId);
		}

		return () => onResetState();
	}, [activeRelationshipSettingId]);

	useEffect(() => {
		if (isRelationshipSettingAddFormOpen) {
			onResetState();
		}
	}, [isRelationshipSettingAddFormOpen, onResetState]);

	const handleRelationshipSettingsFormOpenButtonClick = mode => {
		if (activeRelationshipSettingId) {
			query.set('id', activeRelationshipSettingId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onClearForm();
			query.delete('mode');
			query.delete('fromModal');
			if (pathname === '/companies_relationship') {
				if (fromModal === 'relationship-add') {
					query.set('modal', 'relationship');
					query.set('mode', 'add');
				}
			}

			if (pathname === '/companies_relationship' && relationshipId) {
				if (fromModal === 'relationship-edit') {
					query.set('modal', 'relationship');
					query.set('mode', 'edit');
					query.set('id', relationshipId);
				}
				query.set('modal', 'relationship');
				query.set('id', relationshipId);
			}
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');
		query.delete('fromModal');
		onResetState();
		onClearForm();
		history.push(`${pathname}?${query}`);
	};

	const handleRelationshipSettingDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () =>
					onDeleteRelationshipSettings({
						id: activeRelationshipSettingId,
						redirect: handleModalClose,
						query,
					}),
			},
		});
	};

	const handleNavItemClick = modal => {
		if (modal === 'info') {
			query.delete('tab');
		}

		if (modal === 'audit') {
			query.set('tab', 'audit');
		}
		history.push(`${pathname}?${query}`);
	};

	const modalTabContent = useMemo(() => {
		if (isAuditTabShown) {
			return <Audit entityName="relationship_settings" entityId={activeRelationshipSettingId} />;
		}

		return null;
	}, [isAuditTabShown]);

	const tabs = useMemo(() => {
		const tabs = [
			{
				id: 'info',
				text: 'Информация',
				onClick: () => handleNavItemClick('info'),
				isActive: !tab && !mode,
			},
			{
				id: 'audit',
				text: 'Изменения',
				onClick: () => handleNavItemClick('audit'),
				isActive: isAuditTabShown && !mode,
			},
		];

		if (relationshipSettingTabs.audit) {
			tabs.push({
				id: 'audit',
				text: 'Изменения',
				onClick: () => handleNavItemClick('audit'),
				isActive: isAuditTabShown && !mode,
			});
		}

		return tabs;
	}, [relationshipSettingTabs, tab, mode, isAuditTabShown]);

	const handleRelationshipSettingFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeRelationshipSettingId) {
			onUpdateRelationshipSettings({
				id: activeRelationshipSettingId,
				searchQuery: query.toString(),
				redirect: () => {
					query.delete('mode');
					query.delete('fromModal');
					query.delete('relationshipId');
					// query.delete('modal');

					if (pathname === '/companies_relationship') {
						if (fromModal === 'relationship-add') {
							query.set('modal', 'relationship');
							query.set('mode', 'add');
						}
						if (fromModal === 'relationship-edit') {
							query.set('modal', 'relationship');
							query.set('mode', 'edit');
							query.set('id', relationshipId);
						}
					}

					history.push(`${pathname}?${query}`);
				},
			});
			return;
		}

		onSendSettings({
			redirect: () => {
				query.delete('mode');
				query.delete('fromModal');
				query.delete('relationshipId');
				query.delete('modal');

				if (pathname === '/companies_relationship') {
					if (fromModal === 'relationship-add') {
						query.set('modal', 'relationship');
						query.set('mode', 'add');
					}
					if (fromModal === 'relationship-edit') {
						query.set('modal', 'relationship');
						query.set('mode', 'edit');
						query.set('id', relationshipId);
					}
				}

				history.push(`${pathname}?${query}`);
			},
			id: query.get('id'),
			searchQuery: removeModalQuery(query.toString()),
			query,
			resetState: onResetState,
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleRelationshipSettingFormSubmit();
		}
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isRelationshipSettingAddFormOpen || isRelationshipSettingEditFormOpen
					? `${isRelationshipSettingEditFormOpen ? 'Изменить настройку' : 'Создать настройку'}`
					: relationshipSettingHeader
			}
			navItems={isRelationshipSettingAddFormOpen || isRelationshipSettingEditFormOpen ? [] : tabs}
			modalContent={
				!tab ? (
					<RelationshipSettingsContent
						relationshipSettings={relationshipSettings}
						isRelationshipSettingInfoLoading={isRelationshipSettingInfoLoading}
						isRelationshipSettingAddFormOpen={
							isRelationshipSettingAddFormOpen || isRelationshipSettingEditFormOpen
						}
						stages={stages}
						documentTypes={documentTypes}
						isDocumentsTypesLoading={isDocumentsTypesLoading}
						isStagesLoading={isStagesLoading}
						formValues={formValues}
						change={change}
					/>
				) : (
					modalTabContent
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'relationship_settings' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isRelationshipSettingAddFormOpen || isRelationshipSettingEditFormOpen) &&
						isAvailable.delete &&
						!isAuditTabShown
							? [
									{
										onClick: handleRelationshipSettingDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isRelationshipSettingInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isRelationshipSettingAddFormOpen || isRelationshipSettingEditFormOpen
							? [
									{
										onClick: handleRelationshipSettingFormSubmit,
										text: 'Сохранить',
										color: 'success',
										loading: isFormSending,
										disabled: isFormSending,
									},
									{
										onClick: () => handleRelationshipSettingsFormOpenButtonClick('cancel'),
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
							: [
									...(isAvailable.update && !tab
										? [
												{
													onClick: () =>
														handleRelationshipSettingsFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isRelationshipSettingInfoLoading,
												},
										  ]
										: []),
									{
										onClick: () => {
											if (relationshipId) {
												handleRelationshipSettingsFormOpenButtonClick('cancel');
											} else {
												handleModalClose();
											}
										},
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	isModalShown: getIsModalShownSelector(),
	isFormSending: getIsFormSendingSelector(),
	relationshipSettings: getRelationshipSettingInfoSelector(),
	stages: getStagesSelector(),
	documentTypes: getDocumentTypesOptionsSelector(),
	isStagesLoading: getIsStagesLoadingSelector(),
	isDocumentsTypesLoading: getIsDocumentsTypesLoadingSelector(),
	isRelationshipSettingAddFormOpen: getIsRelationshipSettingAddFormOpenSelector(),
	relationshipSettingTabs: getRelationshipSettingTabsSelector(),
	activeRelationshipSettingId: getActiveRelationshipSettingIdSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	isRelationshipSettingInfoLoading: getIsRelationshipSettingLoadingSelector(),
	relationshipSettingHeader: getRelationshipSettingHeaderSelector(),
	formValues: getFormValues(FORM_NAME),
	initialValues: getFormInitialValuesSelector(),
	isAvailable: getIsAvailableRelationshipSettingsButtonsSelector(),
	isRelationshipSettingEditFormOpen: getIsRelationshipSettingEditFormOpenSelector(),
	relationshipInfo: getRelationshipInfoSelector(),
});

const mapDispatchToProps = {
	onGetRelationshipSetting: getRelationshipSettingsAction,
	onGetStages: getStagesAction,
	onGetDocumentTypes: group => getDocumentsTypesAction({ group }),
	onResetForm: reset,
	onSetStages: setStagesAction,
	onSetDocumentTypes: setDocumentsTypesAction,
	onFormSubmit: submit,
	onSendSettings: sendSettingsAction,
	onSetWindowInfo: setWindowInfoAction,
	onResetState: resetStateAction,
	onDeleteRelationshipSettings: deleteRelationshipSettingsAction,
	onClearForm: () => reset(FORM_NAME),
	onUpdateRelationshipSettings: updateRelationshipSettingsAction,
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
	keepDirtyOnReinitialize: true,
})(connect(mapStateToProps)(RelationshipSettingsModalWrapper));

const RelationshipSettingsModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

RelationshipSettingsModalWrapper.propTypes = {
	isRelationshipSettingAddFormOpen: PropTypes.bool.isRequired,
	onGetStages: PropTypes.func.isRequired,
	onGetDocumentTypes: PropTypes.func.isRequired,
	onSetStages: PropTypes.func.isRequired,
	isFormSending: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onSetDocumentTypes: PropTypes.func.isRequired,
	stages: PropTypes.array,
	documentTypes: PropTypes.array,
	isDocumentsTypesLoading: PropTypes.bool.isRequired,
	isStagesLoading: PropTypes.bool.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSendSettings: PropTypes.func.isRequired,
	onGetRelationshipSetting: PropTypes.func.isRequired,
	relationshipSettings: PropTypes.object,
	isRelationshipSettingInfoLoading: PropTypes.bool.isRequired,
	relationshipSettingHeader: PropTypes.string.isRequired,
	activeRelationshipSettingId: PropTypes.string,
	isAuditTabShown: PropTypes.bool.isRequired,
	onResetState: PropTypes.func.isRequired,
	relationshipSettingTabs: PropTypes.shape({
		audit: PropTypes.bool,
	}).isRequired,
	formValues: PropTypes.object,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	onRelationshipSettings: PropTypes.func.isRequired,
	onDeleteRelationshipSettings: PropTypes.func.isRequired,
	onClearForm: PropTypes.func.isRequired,
	isRelationshipSettingEditFormOpen: PropTypes.bool.isRequired,
	onUpdateRelationshipSettings: PropTypes.func.isRequired,
	change: PropTypes.func.isRequired,
	relationshipInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	),
};

export default RelationshipSettingsModal;
