import { CustomCell } from '../../components/custom-cell';
import { MarkerCell } from './components/marker-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADDRESSES_TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{ title: 'Название', dataIndex: 'name', render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }) },
	{ title: 'Адрес', dataIndex: 'address' },
	{
		title: '',
		dataIndex: 'marker',
		width: 100,
		align: 'center',
		render: (_, rowData) => MarkerCell({ rowData, columnId: 'marker' }),
	},
];
