import React from 'react';
import PropTypes from 'prop-types';

import { RelationshipSettingsFormView } from './settings-form-view';

export const RelationshipSettingsForm = ({
	isDocumentsTypesLoading,
	isStagesLoading,
	documentTypes,
	change,
	formValues,
	stages,
	relationshipSettings,
}) => {
	return (
		<RelationshipSettingsFormView
			isDocumentsTypesLoading={isDocumentsTypesLoading}
			isStagesLoading={isStagesLoading}
			stages={stages}
			documentTypes={documentTypes}
			change={change}
			formValues={formValues}
			relationshipSettings={relationshipSettings}
		/>
	);
};

RelationshipSettingsForm.propTypes = {
	isDocumentsTypesLoading: PropTypes.bool.isRequired,
	isStagesLoading: PropTypes.bool.isRequired,
	stages: PropTypes.array.isRequired,
	documentTypes: PropTypes.array.isRequired,
	change: PropTypes.func.isRequired,
	formValues: PropTypes.object,
	initialValues: PropTypes.array,
	relationshipSettings: PropTypes.object,
};
