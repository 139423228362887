import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import {
	GET_STAGES,
	setStagesAction,
	setIsStagesLoadingAction,
	setDocumentsTypesAction,
	GET_DOCUMENTS_TYPES,
	setIsDocumentsTypesLoadingAction,
	SEND_SETTINGS,
	setIsFormSendingAction,
	setIsRelationshipSettingsLoadingAction,
	GET_RELATIONSHIP_SETTINGS,
	setRelationshipSettingsAction,
	setPaginationAction,
	setIsRelationshipSettingsDeletingAction,
	DELETE_RELATIONSHIP_SETTINGS,
	setIsRelationshipSettingsAction,
	UPDATE_RELATIONSHIP_SETTINGS,
} from './actions';
import { FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { DISMISS_TIME } from '../../api/constants';
import { errorHandler } from '../../api/utils';
import {
	getDocumentTypesRequest,
	getStagesRequest,
	createRelationshipSettingsRequest,
	getRelationshipSettings,
	deleteRelationshipSettingsRequest,
	updateRelationshipSettingsRequest,
} from '../../api/requests';
import { getFormattedSettingsDataSelector, getFormIsInvalidSelector } from './selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getRelationshipSettingsListAction, setRelationshipSettingsListAction } from '../relationship-settings/actions';
import { getRawPaginationSelector, getRelationshipSettingsListSelector } from '../relationship-settings/selectors';

export function* getStagesSaga() {
	try {
		yield put(setIsStagesLoadingAction(true));
		const response = yield call(getStagesRequest);

		const { data, message, toast } = response;

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setStagesAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsStagesLoadingAction(false));
	}
}

export function* getDocumentsTypesSaga(action) {
	const { group } = action.payload;
	try {
		yield put(setIsDocumentsTypesLoadingAction(true));
		const response = yield call(getDocumentTypesRequest, group);

		const { data, message, toast } = response;

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(setDocumentsTypesAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsDocumentsTypesLoadingAction(false));
	}
}

export function* sendSettingSaga({ payload: { redirect, query } }) {
	try {
		yield put(setIsFormSendingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());
		if (isInvalid) return;

		const dataForRequest = yield select(getFormattedSettingsDataSelector());

		const { data, message, toast, errors } = yield call(createRelationshipSettingsRequest, dataForRequest);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
					}),
				);
			}
			redirect();
			yield put(
				getRelationshipSettingsListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }),
			);
		} else {
			if (errors) {
				yield put(touch(FORM_NAME));
				yield put(stopSubmit(FORM_NAME, errors));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsFormSendingAction(false));
	}
}

export function* getRelationshipSettingsSaga({ payload }) {
	try {
		yield put(setIsRelationshipSettingsLoadingAction(true));

		const { data, message, toast } = yield call(getRelationshipSettings, payload);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setRelationshipSettingsAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipSettingsLoadingAction(false));
	}
}

export function* deleteRelationshipSettingsSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsRelationshipSettingsDeletingAction(true));

		const { data, message, toast } = yield call(deleteRelationshipSettingsRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const relationshipSettings = yield select(getRelationshipSettingsListSelector());
			if (Array.isArray(relationshipSettings)) {
				yield put(setRelationshipSettingsAction(relationshipSettings.filter(({ id }) => id !== data.id)));
			}

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(
				getRelationshipSettingsListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipSettingsDeletingAction(false));
	}
}

export function* updateRelationshipSettingsSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsRelationshipSettingsAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());
		if (isInvalid) return;

		const dataForSave = yield select(getFormattedSettingsDataSelector());

		const { data, errors, message, toast } = yield call(updateRelationshipSettingsRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const settings = yield select(getRelationshipSettingsListSelector());

			const updatedSetting = {
				...data,
				type_name: data.type?.name ?? '---',
				link: `/home/relationships?${searchQuery ? `${searchQuery}&` : ''}modal=relationship_settings&id=${
					data.id
				}`,
			};

			yield put(
				setRelationshipSettingsListAction(
					settings.map(setting => {
						return setting.id === id ? updatedSetting : setting;
					}),
				),
			);

			yield put(setRelationshipSettingsAction(data));
			redirect();
			yield put(
				getRelationshipSettingsListAction({
					searchQuery: removeModalQuery(searchQuery.toString()),
					noLoading: true,
				}),
			);
		} else {
			yield put(touch(FORM_NAME));
			yield put(stopSubmit(FORM_NAME, errors));
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipSettingsAction(false));
	}
}

export default function* relationshipSettingsModalSaga() {
	yield takeEvery(GET_STAGES, getStagesSaga);
	yield takeEvery(GET_DOCUMENTS_TYPES, getDocumentsTypesSaga);
	yield takeEvery(SEND_SETTINGS, sendSettingSaga);
	yield takeEvery(GET_RELATIONSHIP_SETTINGS, getRelationshipSettingsSaga);
	yield takeEvery(DELETE_RELATIONSHIP_SETTINGS, deleteRelationshipSettingsSaga);
	yield takeEvery(UPDATE_RELATIONSHIP_SETTINGS, updateRelationshipSettingsSaga);
}
