import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RulesForm } from '../rules-form';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';

export const UserRoleForm = ({ isUserRoleInfoLoading, tableRows, tableColumns, onCheckboxChange, formValues }) => {
	return (
		<div className="user-role-form">
			<div className="user-role-form__field-wrapper">
				<div className="user-role-form__field">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите название роли"
						loading={isUserRoleInfoLoading}
					/>
				</div>
			</div>
			<RulesForm
				isUserRoleInfoLoading={isUserRoleInfoLoading}
				tableRows={tableRows}
				tableColumns={tableColumns}
				onCheckboxChange={onCheckboxChange}
				formValues={formValues}
				isUserRoleFormOpen
			/>
		</div>
	);
};

UserRoleForm.propTypes = {
	isUserRoleInfoLoading: PropTypes.bool.isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])))
		.isRequired,
	tableRows: PropTypes.objectOf(
		PropTypes.shape({ title: PropTypes.string.isRequired, actions: PropTypes.arrayOf(PropTypes.string) }),
	),
	onCheckboxChange: PropTypes.func.isRequired,
	formValues: PropTypes.object,
};
