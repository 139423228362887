import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccreditationContentView } from './components/accreditation-content';
import { getAccreditationtDataAction, resetStateAction } from './actions';
import { getAccreditationDataSelector, getIsAccreditationDataLoadingSelector } from './selectors';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { getCarTypeIdSelector } from '../../containers/cars-modal/selectors';
import { getTrailerTypeIdSelector } from '../../containers/trailers-modal/selectors';

const AccreditationContentWrapper = ({
	type,
	id,
	accreditationData,
	isAccreditationDataLoading,
	onGetAccreditationData,
	onResetState,
	trailerTypeId,
	carTypeId,
}) => {
	useInjectSaga({ key: 'accreditationComponentSaga', saga });
	useInjectReducer({ key: 'accreditationComponentReducer', reducer });

	const typeId = type === 'cars' ? carTypeId : trailerTypeId;

	useEffect(() => {
		onGetAccreditationData({ type, id });
	}, [type, id, onGetAccreditationData]);

	useEffect(() => {
		onGetAccreditationData({ type, id });

		return () => {
			onResetState();
		};
	}, []);

	return (
		<AccreditationContentView
			accreditationData={accreditationData}
			isAccreditationDataLoading={isAccreditationDataLoading}
			typeId={typeId}
			type={type}
		/>
	);
};

const makeMapStateToProps = (_, ownProps) => {
	const selectAccreditationData = getAccreditationDataSelector(ownProps.typeId);

	return createStructuredSelector({
		accreditationData: selectAccreditationData,
		isAccreditationDataLoading: getIsAccreditationDataLoadingSelector(),
		carTypeId: getCarTypeIdSelector(),
		trailerTypeId: getTrailerTypeIdSelector(),
	});
};

const mapDispatchToProps = {
	onGetAccreditationData: getAccreditationtDataAction,
	onResetState: resetStateAction,
};

AccreditationContentWrapper.propTypes = {
	accreditationData: PropTypes.string,
	isAccreditationDataLoading: PropTypes.bool.isRequired,
	onGetAccreditationData: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	trailerTypeId: PropTypes.number,
	carTypeId: PropTypes.number,
};

const Accreditation = connect(makeMapStateToProps, mapDispatchToProps)(AccreditationContentWrapper);

export { Accreditation };
