import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.less';
import { FormCheckbox, Loader } from 'semantic-ui-react';

export const TodoView = ({
	todo,
	navItems,
	isInformationLoading,
	activeNavItem,
	onNavItemClick,
	isInfoCompanyChecked,
	onInfoCompanyCheckboxChange,
}) => {
	return (
		<div className="todo-component">
			<div className="todo-component__nav">
				{navItems.map(({ key, value, count, color }) => {
					const isActive = activeNavItem === value;

					return (
						<div
							className={`todo-component__nav-item ${isActive ? 'todo-component__nav-item_active' : ''}`}
							onClick={() => onNavItemClick(value)}
							key={key}
						>
							{value}{' '}
							{count > 0 && (
								<span className="todo-component__nav-badge" style={{ backgroundColor: color }}>
									{count}
								</span>
							)}
						</div>
					);
				})}
			</div>

			<header className="todo-component__header">
				<span className="todo-component__header-title">To Do List</span>
				<div className="todo-component__header-checkbox">
					<FormCheckbox
						type="checkbox"
						checked={isInfoCompanyChecked}
						onChange={onInfoCompanyCheckboxChange}
					/>
					<span className="todo-component__header-checkbox-title">Общая информация о компании</span>
				</div>
			</header>
			<ul className="todo-component__info-list">
				{isInformationLoading ? (
					<Loader active size="big" />
				) : (
					<>
						{todo.length > 0 ? (
							todo.map(list => (
								<li key={list.text} className="todo-component__info-list-item">
									<span className="todo-component__info-list-text">{list.text}</span>
									<span className="todo-component__info-list-text todo-component__info-list-text_accent">
										{list.count}
									</span>
									{list.button && (
										<Link to={list.button.link} className="todo-component__info-list-link">
											<div
												className={`todo-component__info-list-button todo-component__info-list-button_${list.button.color}`}
											>
												{list.button.text}
											</div>
										</Link>
									)}
								</li>
							))
						) : (
							<div className="todo-component__empty-message-text">На данный момент задач нет</div>
						)}
					</>
				)}
			</ul>
		</div>
	);
};

TodoView.propTypes = {
	todo: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			button: PropTypes.shape({
				text: PropTypes.string,
				color: PropTypes.string,
				link: PropTypes.string,
			}),
		}),
	),
	navItems: PropTypes.array.isRequired,
	isInformationLoading: PropTypes.bool.isRequired,
	activeNavItem: PropTypes.string.isRequired,
	onNavItemClick: PropTypes.func.isRequired,
	isInfoCompanyChecked: PropTypes.bool.isRequired,
	onInfoCompanyCheckboxChange: PropTypes.func.isRequired,
	badges: PropTypes.objectOf(
		PropTypes.shape({
			count: PropTypes.number.isRequired,
			color: PropTypes.string.isRequired,
		}),
	),
};
