import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { TABS } from './constants';

const selectClaimsContainerDomain = state => state.claimsContainerReducer || initialState;

const selectRouterDomain = state => state.router || {};

export const getClaimsCountSelector = () => createSelector(selectClaimsContainerDomain, ({ count }) => count);

export const getClaimsListSelector = () =>
	createSelector(selectClaimsContainerDomain, selectRouterDomain, ({ claimsList }) =>
		claimsList?.map(claim => ({
			id: claim.id,
			link: claim.link,
			created_at: claim.created_at,
			request_id: claim.req?.id,
			request_link: claim.request_link,
			request_category: claim.req?.category?.name,
			theme: claim.theme?.name,
			category_name: claim.category?.name,
			from_name: claim.from_company?.name,
			to_name: claim.to_company?.name,
			priority_name: claim.priority?.name,
			priority_color: claim.priority?.color,
		})),
	);

export const getIsClaimsListLoadingSelector = () =>
	createSelector(selectClaimsContainerDomain, ({ isClaimsListLoading }) => isClaimsListLoading);

export const getPaginationSelector = () =>
	createSelector(selectClaimsContainerDomain, ({ pagination }) => {
		if (!pagination || pagination.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectClaimsContainerDomain, ({ tableSearch }) => tableSearch);

export const getTabsSelector = () =>
	createSelector(getClaimsCountSelector(), count => {
		if (!count) return TABS;

		return TABS.map(tab => {
			const urlId = tab.href.split('/')[2];

			if (!count[urlId]) {
				count[urlId] = null;
			}

			return {
				...tab,
				count: count[urlId],
			};
		});
	});
