import { CustomCell } from '../../../components/custom-cell';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Название',
		dataIndex: 'name',
		width: '250px',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }),
	},
	{ title: 'ИНН', dataIndex: 'inn', width: '200px' },
	{ title: 'ОГРН', dataIndex: 'ogrn', width: '200px' },
	{ title: 'Контакты', dataIndex: 'contact', render: (_, rowData) => CustomCell({ rowData, columnId: 'contact' }) },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const COMPANIES_NAV_PAGE_LIST = [{ text: 'Компании', href: '/companies' }];
