import { CarrierModalCustomCell } from './components/carrier-modal-custom-cell';

export const TABLE_COLUMNS_NAMES = [
	{
		dataIndex: 'company',
		title: 'Компания',
		render: (_, rowData) => CarrierModalCustomCell({ rowData, columnId: 'company' }),
	},
	{
		dataIndex: 'price',
		title: 'Цена',
		render: (_, rowData) => CarrierModalCustomCell({ rowData, columnId: 'price' }),
	},
	{
		dataIndex: 'date',
		title: 'Дата доставки',
		render: (_, rowData) => CarrierModalCustomCell({ rowData, columnId: 'date' }),
	},
	{
		dataIndex: 'action',
		title: '',
		width: 150,
		align: 'center',
		render: (_, rowData) => CarrierModalCustomCell({ rowData, columnId: 'action' }),
	},
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const DATE_FORMAT = 'DD.MM.YYYY';

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';
