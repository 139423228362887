export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
export const FORM_NAME = 'select_accreditation';

export const TABLE_COLUMNS = [
	{
		title: 'Температурный режим',
		dataIndex: 'mode_name',
		width: 230,
	},
	{
		title: 'Валидация',
		dataIndex: 'is_validated',
	},
	{
		title: 'Действительна до',
		dataIndex: 'date',
	},
	{
		title: 'Одобрен',
		dataIndex: 'is_approved',
	},
];
