import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { TABLE_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';
import { RelationshipForm } from '../relationship-form';
import './styles.less';

export const RelationshipContent = ({
	relationshipInfo,
	isRelationshipInfoLoading,
	isRelationshipFormOpen,
	relationshipSettingsInfo,
}) => {
	return isRelationshipFormOpen ? (
		<RelationshipForm />
	) : (
		<>
			{isRelationshipInfoLoading ? (
				<div className="relationship-content">
					<Loader active size="big" inline />
				</div>
			) : (
				<>
					<SimpleTableComponent
						rows={relationshipInfo}
						isTableDataLoading={isRelationshipInfoLoading}
						columns={TABLE_COLUMNS_NAMES}
					/>
					{relationshipSettingsInfo && (
						<>
							<div className="relationship-content__header_text_section">
								<h5>Информация о заявке</h5>
							</div>
							<SimpleTableComponent
								rows={relationshipSettingsInfo}
								isTableDataLoading={isRelationshipInfoLoading}
								columns={TABLE_COLUMNS_NAMES}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
RelationshipContent.propTypes = {
	relationshipInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	),
	relationshipSettingsInfo: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		}),
	),
	isRelationshipInfoLoading: PropTypes.bool.isRequired,
	isRelationshipFormOpen: PropTypes.bool.isRequired,
};
