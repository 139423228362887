import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Loader } from 'semantic-ui-react';
import { TableComponent } from '../../../table-component';
import { getAccreditationTableRows } from '../../constants';

export const AccreditationContentView = ({ accreditationData, isAccreditationDataLoading, typeId, type }) => {
	return (
		<div className="accreditation-content">
			{isAccreditationDataLoading ? (
				<Loader active size="big" inline />
			) : (
				<>
					{accreditationData ? (
						<TableComponent
							isWithoutFooter
							isWithoutHeader
							isChildren
							columns={getAccreditationTableRows(type, typeId)}
							rows={accreditationData}
							isTableDataLoading={isAccreditationDataLoading}
						/>
					) : (
						<div className="accreditation-content__empty-message">Данные по аккредитации отсутствуют</div>
					)}
				</>
			)}
		</div>
	);
};

AccreditationContentView.propTypes = {
	accreditationData: PropTypes.string,
	isAccreditationDataLoading: PropTypes.bool.isRequired,
	typeId: PropTypes.array.isRequired,
	type: PropTypes.string,
};
