import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';

export const DateCargoCollectionModalFormView = ({ formErrors }) => {
	return (
		<div className="date-cargo-collection-modal-form-wrapper">
			<Grid className="date-cargo-collection-modal-form">
				<Grid.Row className="date-cargo-collection-modal-form__row">
					<Grid.Column width={4} className="date-cargo-collection-modal-form__column">
						Дата
						<div className="date-cargo-collection-modal-form__column-field">
							<Field
								name="fetch_at"
								type="text"
								component={CustomDatePicker}
								autoComplete="off"
								placeholder=". . / . . / . . . . "
								dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								isWithoutErrorText
							/>
						</div>
					</Grid.Column>
					<Grid.Column width={10} className="date-cargo-collection-modal-form__column">
						Время
						<div className="date-cargo-collection-modal-form__column-field">
							<Field
								name="fetch_time_from"
								type="time"
								component={FormInputField}
								autoComplete="off"
								placeholder="Время забора от"
								isWithoutErrorText
							/>
						</div>
						-
						<div className="date-cargo-collection-modal-form__column-field">
							<Field
								name="fetch_time_to"
								type="time"
								component={FormInputField}
								autoComplete="off"
								placeholder="Время забора до"
								isWithoutErrorText
							/>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<div className="date-cargo-collection-modal-form__errors-wrapper">
				{formErrors.map(error => (
					<span className="date-cargo-collection-modal-form__error" key={error}>
						{error}
					</span>
				))}
			</div>
		</div>
	);
};

DateCargoCollectionModalFormView.propTypes = {
	formErrors: PropTypes.arrayOf(PropTypes.string),
};
