import {
	RESET_STATE,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
	SET_RELATIONSHIP_SETTINGS_LIST,
	SET_IS_RELATIONSHIP_SETTINGS_LIST_LOADING,
} from './actions';

export const initialState = {
	pagination: null,
	tableSearch: '',
	relationshipSettingsList: null,
	isRelationshipSettingsListLoading: false,
};

const relationshipSettingsContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_IS_RELATIONSHIP_SETTINGS_LIST_LOADING:
			return {
				...state,
				isRelationshipSettingsListLoading: payload,
			};
		case SET_RELATIONSHIP_SETTINGS_LIST:
			return {
				...state,
				relationshipSettingsList: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default relationshipSettingsContainerReducer;
