export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const NAV_ITEMS = {
	'LTL-перевозки': 'combined',
	'FTL-перевозки': 'car',
	'Срочная заявка': 'express',
	Авиа: 'avia',
};

export const NAV_ITEMS_KEYS = Object.keys(NAV_ITEMS);

export const NAV_ITEMS_TEMP = [
	{
		key: 'combined',
		value: 'LTL-перевозки',
	},
	{ key: 'car', value: 'FTL-перевозки' },
	{ key: 'express', value: 'Срочная заявка' },
	{ key: 'avia', value: ' Авиа' },
];
