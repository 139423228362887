import CaseIcon from '../images/case-icon.svg';
import ProfileIcon from '../images/profile-icon.svg';
import FolderIcon from '../images/folder-add-icon.svg';
import HumansIcon from '../images/humans-icon.svg';
import DictionariesIcon from '../images/dictionaries-icon.svg';
import UserIcon from '../images/user-icon.svg';
import UsersIcon from '../images/users-icon.svg';
import ClaimsIcon from '../images/claims-sidebar-button.svg';
import QualityIcon from '../images/quality-sidebar-button.svg';
import TransportIcon from '../images/track-sidebar-button.svg';
import Relationship from '../images/relationship.svg';
import CombinedIcon from '../images/dashboard-icon.svg';
import CarIcon from '../images/truck-delivery-icon.svg';
import ExpressIcon from '../images/timer-icon.svg';
import AviaIcon from '../images/airplane-icon.svg';

export const MENU_ICONS = {
	home: ProfileIcon,
	reqs: CaseIcon,
	documents: FolderIcon,
	employees: HumansIcon,
	dictionaries: DictionariesIcon,
	users: UserIcon,
	users_roles: UsersIcon,
	companies_relationship: Relationship,
	reqs_claims: ClaimsIcon,
	quality: QualityIcon,
	transports: TransportIcon,
	req_combined: CombinedIcon,
	req_car: CarIcon,
	req_express: ExpressIcon,
	req_avia: AviaIcon,
};
