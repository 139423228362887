import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { reset, stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { FIELDS_FOR_VALIDATE, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import {
	CREATE_RELATIONSHIP,
	DELETE_RELATIONSHIP,
	GET_RELATIONSHIP_INFO,
	GET_SHIPPERS_LIST,
	GET_CARRIERS_LIST,
	setShippersListAction,
	setIsShippersListLoadingAction,
	setIsRelationshipDeletingAction,
	setIsRelationshipInfoLoadingAction,
	setIsRelationshipSavingAction,
	setIsCarriersListLoadingAction,
	setRelationshipInfoAction,
	setCarriersListAction,
	UPDATE_RELATIONSHIP,
	setIsSettingsListLoadingAction,
	setSettingsListAction,
	GET_SETTINGS_LIST,
	setCategoriesListAction,
	setIsCategoriesListLoadingAction,
	GET_CATEGORIES_LIST,
} from './actions';
import {
	createRelationshipItemRequest,
	deleteRelationshipRequest,
	getShippersDictionaryRequest,
	getRelationshipRequest,
	getCarriersDictionaryRequest,
	updateRelationshipRequest,
	getCategoriesRequest,
	getRelationshipSettingsListRequest,
	// getRelationshipSettingsListRequest,
} from '../../api/requests';
import {
	getCurrentCompanySelector,
	getDataForCreateOrUpdateRelationshipSelector,
	getFormIsInvalidSelector,
} from './selectors';
import { getRawPaginationSelector, getUnformattedRelationshipListSelector } from '../relationship/selectors';
import { setPaginationAction, setRelationshipListAction, getRelationshipListAction } from '../relationship/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getRelationshipInfoSaga({ id }) {
	try {
		yield put(setIsRelationshipInfoLoadingAction(true));

		const { data, message, toast } = yield call(getRelationshipRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setRelationshipInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipInfoLoadingAction(false));
	}
}

export function* deleteRelationshipSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsRelationshipDeletingAction(true));

		const { data, message, toast } = yield call(deleteRelationshipRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const relationship = yield select(getUnformattedRelationshipListSelector());
			yield put(setRelationshipListAction(relationship.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getRelationshipListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipDeletingAction(false));
	}
}

export function* createRelationshipSaga({ payload: { redirect, resetState, searchQuery } }) {
	try {
		yield put(setIsRelationshipSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateRelationshipSelector());

		const { data, errors, message, toast } = yield call(createRelationshipItemRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			// yield put(setRelationshipInfoAction(data));

			// const relationship = yield select(getUnformattedRelationshipListSelector());
			// yield put(
			// 	setRelationshipListAction([
			// 		{
			// 			...data,
			// 			link: `/companies_relationship?${searchQuery ? `${searchQuery}&` : ''}modal=relationship&id=${
			// 				data.id
			// 			}`,
			// 		},
			// 		...relationship,
			// 	]),
			// );

			// const pagination = yield select(getRawPaginationSelector());

			// if (pagination) {
			// 	yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			// }

			redirect();
			resetState();
			reset(FORM_NAME);
			yield put(
				getRelationshipListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipSavingAction(false));
	}
}

export function* updateRelationshipSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsRelationshipSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateRelationshipSelector());

		const { data, errors, message, toast } = yield call(updateRelationshipRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const relationship = yield select(getUnformattedRelationshipListSelector());

			const updatedRelationship = {
				...data,
				link: `/companies_relationship?${searchQuery ? `${searchQuery}&` : ''}modal=relationship&id=${data.id}`,
			};

			yield put(
				setRelationshipListAction(
					relationship.map(car => {
						return car.id === data.id ? updatedRelationship : car;
					}),
				),
			);

			yield put(setRelationshipInfoAction(data));
			redirect();
			reset(FORM_NAME);
			yield put(
				getRelationshipListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsRelationshipSavingAction(false));
	}
}

export function* getShippersListSaga() {
	try {
		yield put(setIsShippersListLoadingAction(true));
		const { data, message, toast } = yield call(getShippersDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const currentCompany = yield select(getCurrentCompanySelector());

			yield put(
				setShippersListAction([
					{
						text: currentCompany.name,
						value: currentCompany.id,
						key: `${currentCompany.name.trim()}-${currentCompany.id}`,
					},
					...data?.reduce((list, shipper) => {
						if (shipper.id === currentCompany.id) return list;

						const newShipper = {
							text: shipper.name,
							value: shipper.id,
							key: `${shipper.name.trim()}-${shipper.id}`,
						};

						list.push(newShipper);
						return list;
					}, []),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsShippersListLoadingAction(false));
	}
}

export function* getCarriersListSaga() {
	try {
		yield put(setIsCarriersListLoadingAction(true));
		const { data, message, toast } = yield call(getCarriersDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setCarriersListAction(
					data?.map(carrier => ({
						text: carrier.name,
						value: carrier.id,
						key: `${carrier.name.trim()}-${carrier.id}`,
					})),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCarriersListLoadingAction(false));
	}
}
export function* getCategoriesSaga() {
	try {
		yield put(setIsCategoriesListLoadingAction(true));

		const { data, message, toast } = yield call(getCategoriesRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setCategoriesListAction(
					data
						.sort((a, b) => a.id - b.id)
						.map(category => ({
							text: category.name,
							value: category.id,
							key: `${category.name.trim()}-${category.id}`,
						})),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCategoriesListLoadingAction(false));
	}
}

export function* getSettingsSaga() {
	try {
		yield put(setIsSettingsListLoadingAction(true));

		const { data, message, toast } = yield call(getRelationshipSettingsListRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setSettingsListAction([
					{ text: 'Стандартный жизненный цикл', value: 'none', key: 'none' },
					...data.map(setting => ({
						text: setting.name,
						value: setting.id,
						key: `${setting.name.trim()}-${setting.id}`,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsSettingsListLoadingAction(false));
	}
}

export default function* relationshipModalSaga() {
	yield takeEvery(GET_RELATIONSHIP_INFO, getRelationshipInfoSaga);
	yield takeEvery(DELETE_RELATIONSHIP, deleteRelationshipSaga);
	yield takeEvery(CREATE_RELATIONSHIP, createRelationshipSaga);
	yield takeEvery(UPDATE_RELATIONSHIP, updateRelationshipSaga);
	yield takeEvery(GET_SHIPPERS_LIST, getShippersListSaga);
	yield takeEvery(GET_CARRIERS_LIST, getCarriersListSaga);
	yield takeEvery(GET_SETTINGS_LIST, getSettingsSaga);
	yield takeEvery(GET_CATEGORIES_LIST, getCategoriesSaga);
}
