import { WaysCustomCell } from './components/ways-custom-cell';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => WaysCustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Название',
		id: 'name',
		width: 170,
		render: (_, rowData) => WaysCustomCell({ rowData, columnId: 'name' }),
	},
	{
		title: 'Адрес отгрузки',
		dataIndex: 'address_from',
		render: (_, rowData) => WaysCustomCell({ rowData, columnId: 'address_from' }),
	},
	{
		title: 'Адрес доставки',
		dataIndex: 'address_to',
		render: (_, rowData) => WaysCustomCell({ rowData, columnId: 'address_to' }),
	},
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
