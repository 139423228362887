import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Название',
		dataIndex: 'name',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }),
	},
	{ title: 'Кол. пользователей', dataIndex: 'user_count' },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const NAV_PAGE_LIST = [{ text: 'Роли пользователей', href: '/users_roles' }];
