import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'user-role-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS_NAMES = [
	{ dataIndex: 'access', title: 'Доступ' },
	{ dataIndex: 'create', title: 'Создание' },
	{ dataIndex: 'update', title: 'Изменение' },
	{ dataIndex: 'delete', title: 'Удаление' },
	{ dataIndex: 'export', title: 'Экспорт' },
	{ dataIndex: 'notifications', title: 'Уведомления' },
];

export const TABLE_INFO_COLUMNS_NAMES = [
	{ id: 'title', width: '50%' },
	{ id: 'value', customComponent: CustomModalCell },
];

export const TABLE_INFO_ROWS_NAMES = {
	user_count: 'Кол. пользователей',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name'];
