import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Модель',
		dataIndex: 'name',
		width: '320px',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }),
	},
	{ title: 'Тип прицепа', dataIndex: 'type_name' },
	{ title: 'Номер', dataIndex: 'number' },
	{ title: 'Vin номер', dataIndex: 'vin' },
];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
