/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { useHistory, useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { PartnerModalContent } from './components/partner-modal-content';
import {
	createPartnerAction,
	deletePartnerAction,
	getPartnerInfoAction,
	resetStateAction,
	setPartnerSearchValueAction,
	setPartnersOptionsAction,
	updatePartnerAction,
} from './actions';
import {
	getActivePartnerIdSelector,
	getPartnerInfoSelector,
	getPartnerNameSelector,
	getIsPartnerAddFormOpenSelector,
	getIsPartnerEditFormOpenSelector,
	getIsPartnerInfoLoadingSelector,
	getIsModalShownSelector,
	getIsAuditTabShownSelector,
} from './selectors';
import { PARTNER_FORM_NAME } from './constants';
import AddressesContainer from '../addresses';
import { resetStateAction as resetAddressesState } from '../addresses/actions';
import { getIsAvailableAddressesSectionSelector, getIsAvailablePartnersButtonsSelector } from '../profile/selectors';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const PartnerModalWrapper = ({
	onGetPartnerInfo,
	isPartnerInfoLoading,
	isPartnerEditFormOpen,
	isPartnerAddFormOpen,
	partnerInfo,
	partnerName,
	onResetState,
	onDeletePartner,
	isModalShown,
	activePartnerId,
	onCreatePartner,
	onUpdatePartner,
	onFormSubmit,
	onSetPartnerSearchValue,
	onSetPartnersOptions,
	onResetAddressesState,
	isAvailable,
	onSetWindowInfo,
	isAuditTabShown,
	isAddressesAvailable,
}) => {
	useInjectSaga({ key: 'partnerModalPageSaga', saga });
	useInjectReducer({ key: 'partnerModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const modal = query.get('modal');

	const mode = query.get('mode');

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activePartnerId) onGetPartnerInfo(activePartnerId);
	}, [activePartnerId]);

	const handlePartnerFormOpenButtonClick = mode => {
		if (activePartnerId) {
			query.set('id', activePartnerId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onSetPartnerSearchValue('');
			onSetPartnersOptions([]);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activePartnerId) {
			onResetState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('addressId');
		query.delete('tab');

		onResetState();
		onResetAddressesState();
		history.push(`${pathname}?${query}`);
	};

	const handlePartnerDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeletePartner({ id: activePartnerId, redirect: handleModalClose, query }),
			},
		});
	};

	const handlePartnerFormSubmit = async () => {
		await onFormSubmit(PARTNER_FORM_NAME);

		if (activePartnerId) {
			onUpdatePartner({
				id: activePartnerId,
				searchQuery: query.toString(),
				redirect: history.push,
			});
			return;
		}

		onCreatePartner({ redirect: history.push, resetState: onResetState, searchQuery: query.toString() });
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handlePartnerFormSubmit();
		}
	};

	const handleNavItemClick = modal => {
		query.delete('tab');
		query.set('modal', modal === 'audit' ? 'partner' : modal);

		if (mode) {
			query.delete('mode');
		}

		if (modal === 'partner') {
			onResetAddressesState();
		}

		if (modal === 'addresses') {
			onResetState();
			onResetAddressesState();
		}

		if (modal === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	if (modal === 'addresses') {
		return <AddressesContainer isChildren />;
	}

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isPartnerEditFormOpen || isPartnerAddFormOpen
					? `${isPartnerEditFormOpen ? 'Изменить контрагента' : 'Создать контрагента'}`
					: partnerName
			}
			navItems={
				isPartnerEditFormOpen || isPartnerAddFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('partner'),
								isActive: modal === 'partner' && !isAuditTabShown,
							},
							...(isAddressesAvailable
								? [
										{
											id: 'addresses',
											text: 'Адреса',
											onClick: () => handleNavItemClick('addresses'),
											isActive: modal === 'addresses',
										},
								  ]
								: []),
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: modal === 'partner' && isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<PartnerModalContent
						partnerInfo={partnerInfo}
						isPartnerInfoLoading={isPartnerInfoLoading}
						isPartnerFormOpen={isPartnerEditFormOpen || isPartnerAddFormOpen}
					/>
				) : (
					<Audit entityName="partners" entityId={activePartnerId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'partner' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isPartnerEditFormOpen || isPartnerAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handlePartnerDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isPartnerInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isPartnerEditFormOpen || isPartnerAddFormOpen
							? [
									{
										onClick: handlePartnerFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isPartnerInfoLoading,
									},
									{
										onClick: () => handlePartnerFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isPartnerInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handlePartnerFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isPartnerInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	partnerInfo: getPartnerInfoSelector(),
	isPartnerInfoLoading: getIsPartnerInfoLoadingSelector(),
	isPartnerAddFormOpen: getIsPartnerAddFormOpenSelector(),
	isPartnerEditFormOpen: getIsPartnerEditFormOpenSelector(),
	partnerName: getPartnerNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activePartnerId: getActivePartnerIdSelector(),
	isAvailable: getIsAvailablePartnersButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	isAddressesAvailable: getIsAvailableAddressesSectionSelector(),
});

const mapDispatchToProps = {
	onGetPartnerInfo: getPartnerInfoAction,
	onDeletePartner: deletePartnerAction,
	onResetState: resetStateAction,
	onCreatePartner: createPartnerAction,
	onUpdatePartner: updatePartnerAction,
	onFormSubmit: submit,
	onSetPartnerSearchValue: setPartnerSearchValueAction,
	onSetPartnersOptions: setPartnersOptionsAction,
	onResetAddressesState: resetAddressesState,
	onSetWindowInfo: setWindowInfoAction,
};

const PartnerModal = connect(mapStateToProps, mapDispatchToProps)(PartnerModalWrapper);

PartnerModalWrapper.propTypes = {
	onGetPartnerInfo: PropTypes.func.isRequired,
	partnerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isPartnerInfoLoading: PropTypes.bool.isRequired,
	isPartnerEditFormOpen: PropTypes.bool.isRequired,
	isPartnerAddFormOpen: PropTypes.bool.isRequired,
	partnerName: PropTypes.string.isRequired,
	onDeletePartner: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activePartnerId: PropTypes.string,
	onCreatePartner: PropTypes.func.isRequired,
	onUpdatePartner: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSetPartnerSearchValue: PropTypes.func.isRequired,
	onSetPartnersOptions: PropTypes.func.isRequired,
	onResetAddressesState: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	isAddressesAvailable: PropTypes.bool,
};

export default PartnerModal;
