/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import { initialState } from './reducer';
import { FORM_NAME, TABLE_COLUMNS_NAMES, TABLE_INFO_ROWS_NAMES } from './constants';
import { filterTruePropertiesToArray } from './utils';

export const selectUsersRolesModalDomain = state => state.usersRolesModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getUserRoleInfoSelector = () =>
	createSelector(selectUsersRolesModalDomain, ({ userRoleInfo }) => {
		if (!userRoleInfo) {
			return [];
		}

		const data = {
			user_count: userRoleInfo.user_count || '-',
			author: {
				name: `${userRoleInfo.user?.last_name || '-'} ${userRoleInfo.user?.first_name || '-'}`,
				role: userRoleInfo.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: TABLE_INFO_ROWS_NAMES[key], value }];
		}, []);
	});

export const getIsUserRoleInfoLoadingSelector = () =>
	createSelector(
		selectUsersRolesModalDomain,
		({ isUserRoleInfoLoading, isUserRoleDeleting, isUserRoleSaving, isRulesLoading }) =>
			isUserRoleInfoLoading || isUserRoleDeleting || isUserRoleSaving || isRulesLoading,
	);

export const getUserRoleNameSelector = () =>
	createSelector(selectUsersRolesModalDomain, ({ userRoleInfo }) => userRoleInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user_role');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user_role' && query?.tab === 'audit');
	});

export const getActiveUserRoleIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'user_role' ? query?.id : null));

export const getIsUserRoleAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user_role' && query?.mode === 'add');
	});

export const getIsUserRoleEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'user_role' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectUsersRolesModalDomain, ({ userRoleInfo }) => {
		if (!userRoleInfo) {
			return {};
		}

		return userRoleInfo;
	});

export const getDataForCreateOrUpdateUserRoleSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => formValues);

export const getTableColumnsSelector = () =>
	createSelector(selectUsersRolesModalDomain, ({ rules }) => {
		const arrayOfRules = Object.values(rules);

		const uniqueActions = [];

		for (const key in arrayOfRules) {
			const actions = arrayOfRules[key].actions;
			for (const action in actions) {
				if (!uniqueActions.includes(action)) {
					uniqueActions.push(action);
				}
			}
		}

		return [
			{ dataIndex: 'name', title: '', width: 230 },
			...TABLE_COLUMNS_NAMES.filter(({ dataIndex }) => uniqueActions.includes(dataIndex)),
		];
	});

export const getTableRowsSelector = () =>
	createSelector(selectUsersRolesModalDomain, ({ rules }) => {
		if (!rules) {
			return {};
		}

		return Object.values(rules).reduce((result, rule) => {
			return {
				...result,
				[rule.route]: { title: rule.title, actions: filterTruePropertiesToArray(rule.actions) },
			};
		}, {});
	});

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);
