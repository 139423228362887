import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';
import './styles.less';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import TablePagination from '../../semantic-ui/components/table-pagination';

const TableComponent = ({
	headerText,
	onAddButtonClick,
	rowKey,
	columns,
	rows,
	nestedColumns,
	pagination,
	onSearch,
	isTableDataLoading,
	isWithoutHeader,
	isWithoutTableHeader,
	searchInputValue = '',
	onSearchInputChange,
	onSearchInputClear,
	isChildren,
	isChildrenWithBorder,
	isInfo,
	isWithoutBoldFirstColumn,
	onDownloadButtonClick,
	customHeaderClassName,
	customRootClassName,
	customClassName,
	customRowClassName,
	size,
	nestedSize,
}) => {
	const expandedRowRender = (id, data) => {
		if (!data || data.length === 0) {
			return null;
		}

		return (
			<Table
				columns={nestedColumns}
				dataSource={data}
				pagination={false}
				rowKey={rowKey ?? 'id'}
				size={nestedSize ?? 'small'}
			/>
		);
	};

	return (
		<div className="table-container__wrapper">
			<div
				className={`${customHeaderClassName || ''} ${
					isWithoutHeader && !customHeaderClassName ? 'table-container__without-header ' : ''
				}${isChildren || isChildrenWithBorder ? 'table-container_children ' : ''}${
					isChildrenWithBorder ? 'table-container_children_with-border ' : ''
				}${isInfo ? 'table-container_info ' : ''}${
					isWithoutBoldFirstColumn ? 'table-container__without-bold-first-column ' : ''
				}table-container`}
			>
				{!isWithoutHeader && !isChildren && (
					<div className="table-container__header">
						<div className="table-container__header-left-side">
							<div className="table-container__header-title">{headerText}</div>
							<div className="table-container__header-buttons">
								{onDownloadButtonClick && (
									<div
										className="table-container__header-download-button"
										onClick={onDownloadButtonClick}
									/>
								)}
								{onAddButtonClick && (
									<div className="table-container__header-button" onClick={onAddButtonClick} />
								)}
							</div>
						</div>

						{onSearch && (
							<div className="table-container__header-right-side">
								<div className="table-container__header-right-side-search">
									<Input
										input={{
											onKeyDown: onSearch,
											value: searchInputValue,
											onChange: onSearchInputChange,
										}}
										placeholder="Поиск"
										icon={
											searchInputValue ? (
												<Icon name="remove" link onClick={onSearchInputClear} />
											) : null
										}
									/>
								</div>
							</div>
						)}
					</div>
				)}
				<div className="table-container__table">
					<Table
						className={`${customClassName || ''}`}
						rootClassName={`${customRootClassName || ''}`}
						rowClassName={`${customRowClassName || ''}`}
						columns={columns}
						dataSource={rows}
						rowKey={rowKey ?? 'id'}
						size={size ?? 'middle'}
						pagination={false}
						loading={isTableDataLoading}
						showHeader={!isWithoutTableHeader}
						sticky
						scroll={{ x: 'max-content' }}
						expandable={
							nestedColumns && {
								expandedRowRender: record => expandedRowRender(record.id, record.data),
								defaultExpandedRowKeys: [],
								expandIcon: ({ expanded, onExpand, record }) => {
									if (!record.data || record.data.length === 0) {
										return null;
									}

									return (
										<a onClick={e => onExpand(record, e)}>
											{expanded ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
										</a>
									);
								},
							}
						}
					/>
					{pagination && <TablePagination pagination={pagination} isShort />}
				</div>
			</div>
		</div>
	);
};

TableComponent.propTypes = {
	headerText: PropTypes.string,
	onAddButtonClick: PropTypes.func,
	rowKey: PropTypes.string,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			customComponent: PropTypes.elementType,
			width: PropTypes.string,
		}),
	),
	nestedColumns: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			customComponent: PropTypes.elementType,
			width: PropTypes.string,
		}),
	),
	rows: PropTypes.arrayOf(PropTypes.object),
	pagination: PropTypes.shape({
		currentPage: PropTypes.number,
		totalPages: PropTypes.number.isRequired,
		total: PropTypes.number,
		from: PropTypes.number,
		to: PropTypes.number,
		isNextItem: PropTypes.bool,
		isPrevItem: PropTypes.bool,
		isFirstItem: PropTypes.bool,
		isLastItem: PropTypes.bool,
		onPageChange: PropTypes.func.isRequired,
		onSelectChange: PropTypes.func.isRequired,
		isPaginationTextHidden: PropTypes.bool,
	}),
	onSearch: PropTypes.func,
	isTableDataLoading: PropTypes.bool.isRequired,
	isWithoutTableHeader: PropTypes.bool,
	isWithoutHeader: PropTypes.bool,
	isWithoutFooter: PropTypes.bool,
	searchInputValue: PropTypes.string,
	onSearchInputChange: PropTypes.func,
	onSearchInputClear: PropTypes.func,
	isChildren: PropTypes.bool,
	isChildrenWithBorder: PropTypes.bool,
	isInfo: PropTypes.bool,
	isWithoutBoldFirstColumn: PropTypes.bool,
	onCustomCellButtonClick: PropTypes.func,
	onDownloadButtonClick: PropTypes.func,
	customHeaderClassName: PropTypes.string,
	customClassName: PropTypes.string,
	customRootClassName: PropTypes.string,
	customRowClassName: PropTypes.string,
	size: PropTypes.string,
	nestedSize: PropTypes.string,
};

export { TableComponent };
