import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { notificationInit } from '../../modules/notifications/actions';
import { REQUEST_ERROR_MESSAGE } from './constants';
import { GET_PARTNERS, setPartnersAction, setPaginationAction, setIsPartnersLoadingAction } from './actions';
import { DISMISS_TIME } from '../../api/constants';
import { getPartnersRequest } from '../../api/partners-requests';
import { errorHandler } from '../../api/utils';

export function* getPartnersSaga({ payload: { searchQuery, noLoading } }) {
	try {
		if (!noLoading) {
			yield put(setIsPartnersLoadingAction(true));
		}

		const { data, message, meta, toast } = yield call(getPartnersRequest, searchQuery);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}
			yield put(
				setPartnersAction(
					data?.map(partner => ({
						...partner,
						link: `/home/partners?${searchQuery ? `${searchQuery}&` : ''}modal=partner&id=${partner.id}`,
					})),
				),
			);

			if (meta) {
				yield put(
					setPaginationAction({
						current_page: meta.current_page,
						per_page: meta.per_page,
						from: meta.from,
						last_page: meta.last_page,
						total: meta.total,
					}),
				);
			}
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsPartnersLoadingAction(false));
	}
}

export default function* partnersSaga() {
	yield takeEvery(GET_PARTNERS, getPartnersSaga);
}
