export const GET_STAGES = 'relationship-settings-modal/GET_STAGES';
export const getStagesAction = payload => ({
	type: GET_STAGES,
	payload,
});

export const SET_STAGES = 'relationship-settings-modal/SET_STAGES';
export const setStagesAction = payload => ({
	type: SET_STAGES,
	payload,
});

export const SET_IS_STAGES_LOADING = 'relationship-settings-modal/SET_IS_STAGES_LOADING';
export const setIsStagesLoadingAction = payload => ({
	type: SET_IS_STAGES_LOADING,
	payload,
});

export const SEND_SETTINGS = 'relationship-settings-modal/SEND_SETTINGS';
export const sendSettingsAction = payload => ({
	type: SEND_SETTINGS,
	payload,
});

export const GET_DOCUMENTS_TYPES = 'relationship-settings-modal/GET_DOCUMENTS_TYPES';
export const getDocumentsTypesAction = payload => ({
	type: GET_DOCUMENTS_TYPES,
	payload,
});
export const SET_DOCUMENTS_TYPES = 'relationship-settings-modal/SET_DOCUMENTS_TYPES';
export const setDocumentsTypesAction = payload => ({
	type: SET_DOCUMENTS_TYPES,
	payload,
});
export const SET_IS_DOCUMENTS_TYPES_LOADING = 'relationship-settings-modal/SET_IS_DOCUMENTS_TYPES_LOADING';
export const setIsDocumentsTypesLoadingAction = payload => ({
	type: SET_IS_DOCUMENTS_TYPES_LOADING,
	payload,
});

export const SET_IS_FORM_SENDING = 'relationship-settings-modal/SET_IS_FORM_SENDING';
export const setIsFormSendingAction = payload => ({
	type: SET_IS_FORM_SENDING,
	payload,
});
export const GET_RELATIONSHIP_SETTINGS = 'relationship-settings-modal/GET_RELATIONSHIP_SETTINGS';
export const getRelationshipSettingsAction = payload => ({
	type: GET_RELATIONSHIP_SETTINGS,
	payload,
});

export const SET_RELATIONSHIP_SETTINGS = 'relationship-settings-modal/SET_RELATIONSHIP_SETTINGS';
export const setRelationshipSettingsAction = payload => ({
	type: SET_RELATIONSHIP_SETTINGS,
	payload,
});
export const SET_IS_RELATIONSHIP_SETTINGS_LOADING = 'relationship-settings-modal/SET_IS_RELATIONSHIP_SETTINGS_LOADING';
export const setIsRelationshipSettingsLoadingAction = payload => ({
	type: SET_IS_RELATIONSHIP_SETTINGS_LOADING,
	payload,
});

export const DELETE_RELATIONSHIP_SETTINGS = 'relationship-settings-modal/DELETE_RELATIONSHIP_SETTINGS';
export const deleteRelationshipSettingsAction = payload => ({
	type: DELETE_RELATIONSHIP_SETTINGS,
	payload,
});

export const SET_IS_RELATIONSHIP_SETTINGS_DELETING =
	'relationship-settings-modal/SET_IS_RELATIONSHIP_SETTINGS_DELETING';
export const setIsRelationshipSettingsDeletingAction = payload => ({
	type: SET_IS_RELATIONSHIP_SETTINGS_DELETING,
	payload,
});
export const SET_PAGINATION = 'relationship-settings-modal/SET_PAGINATION';
export const setPaginationAction = payload => ({
	type: SET_PAGINATION,
	payload,
});

export const RESET_STATE = 'relationship-settings-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const UPDATE_RELATIONSHIP_SETTINGS = 'relationship-settings-modal/UPDATE_RELATIONSHIP_SETTINGS';
export const updateRelationshipSettingsAction = payload => ({
	type: UPDATE_RELATIONSHIP_SETTINGS,
	payload,
});

export const SET_IS_RELATIONSHIP_SETTINGS_SAVING = 'relationship-settings-modal/SET_IS_RELATIONSHIP_SETTINGS_SAVING';
export const setIsRelationshipSettingsAction = payload => ({
	type: SET_IS_RELATIONSHIP_SETTINGS_SAVING,
	payload,
});
