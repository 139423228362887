import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getReqClaimsSelector, getIsReqClaimsLoadingSelector } from '../../selectors';
import { CLAIMS_TABLE_COLUMNS } from '../../constants';
import { TableComponent } from '../../../../components/table-component';

const ReqsClaimsTabWrapper = ({ claimsData, isClaimsLoading }) => {
	return (
		<TableComponent
			columns={CLAIMS_TABLE_COLUMNS}
			rows={claimsData}
			isTableDataLoading={isClaimsLoading}
			isWithoutFooter
			isWithoutHeader
			isChildren
			isInfo
		/>
	);
};

ReqsClaimsTabWrapper.propTypes = {
	claimsData: PropTypes.array,
	isClaimsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
	claimsData: getReqClaimsSelector(),
	isClaimsLoading: getIsReqClaimsLoadingSelector(),
});

export const ReqsClaims = connect(mapStateToProps)(ReqsClaimsTabWrapper);
