import { SET_IS_DOCUMENTS_LOADING } from '../document-attachment-form/actions';
import {
	SET_STAGES,
	SET_IS_STAGES_LOADING,
	SET_IS_FORM_SENDING,
	SET_DOCUMENTS_TYPES,
	SET_IS_RELATIONSHIP_SETTINGS_LOADING,
	SET_RELATIONSHIP_SETTINGS,
	SET_IS_RELATIONSHIP_SETTINGS_DELETING,
	SET_IS_RELATIONSHIP_SETTINGS_SAVING,
	RESET_STATE,
} from './actions';

export const initialState = {
	stages: [],
	isStagesLoading: false,
	isFormSending: false,
	documentTypes: [],
	isDocumentsTypesLoading: false,
	relationshipSettings: null,
	isRelationshipSettingLoading: false,
	isRelationshipSettingsDelete: false,
	isRelationshipSettingsSaving: false,
};

const relationshipSettingsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_STAGES:
			return {
				...state,
				stages: payload,
			};
		case SET_IS_STAGES_LOADING:
			return {
				...state,
				isStagesLoading: payload,
			};
		case SET_DOCUMENTS_TYPES:
			return {
				...state,
				documentTypes: payload,
			};
		case SET_IS_DOCUMENTS_LOADING:
			return {
				...state,
				isDocumentsTypesLoading: payload,
			};
		case SET_IS_FORM_SENDING:
			return {
				...state,
				isFormSending: payload,
			};
		case SET_RELATIONSHIP_SETTINGS:
			return {
				...state,
				relationshipSettings: payload,
			};
		case SET_IS_RELATIONSHIP_SETTINGS_LOADING:
			return {
				...state,
				isRelationshipSettingLoading: payload,
			};
		case SET_IS_RELATIONSHIP_SETTINGS_DELETING:
			return {
				...state,
				isRelationshipSettingsDelete: payload,
			};
		case SET_IS_RELATIONSHIP_SETTINGS_SAVING:
			return {
				...state,
				isRelationshipSettingsSaving: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default relationshipSettingsReducer;
