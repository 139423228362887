import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { ADDRESSES_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import {
	createAddressRequest,
	deleteAddressRequest,
	getAddressInfoRequest,
	getAddressesBySearchRequest,
	updateAddressRequest,
} from '../../api/requests';
import {
	createPartnerAddressRequest,
	deletePartnerAddressRequest,
	getPartnerAddressInfoRequest,
	updatePartnerAddressRequest,
} from '../../api/partners-requests';
import {
	CREATE_ADDRESS,
	CREATE_PARTNER_ADDRESS,
	DELETE_ADDRESS,
	DELETE_PARTNER_ADDRESS,
	GET_ADDRESSES_OPTIONS,
	GET_ADDRESS_INFO,
	GET_PARTNER_ADDRESS_INFO,
	UPDATE_ADDRESS,
	UPDATE_PARTNER_ADDRESS,
	resetStateAction,
	setAddressInfoAction,
	setAddressesOptionsAction,
	setIsAddressCreatingAction,
	setIsAddressDeletingAction,
	setIsAddressInfoLoadingAction,
	setIsAddressUpdatingAction,
	setIsAddressesOptionsLoadingAction,
} from './actions';
import { transformToValidationErrors } from './utils';
import {
	getDataForSaveSelector,
	getAddressSearchValueSelector,
	getDateForUpdateSelector,
	getFormIsInvalidSelector,
} from './selectors';
import { DISMISS_TIME } from '../../api/constants';
import { getAddressesSelector, getRawPaginationSelector } from '../addresses/selectors';
import { getAddressesAction, setAddressesAction, setPaginationAction } from '../addresses/actions';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { errorHandler } from '../../api/utils';
import { getFromStorage } from '../../components/sidebar/utils';

export function* getAddressInfoSaga({ payload: id }) {
	try {
		yield put(setIsAddressInfoLoadingAction(true));

		const { data, message, toast } = yield call(getAddressInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setAddressInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressInfoLoadingAction(false));
	}
}

export function* createAddressSaga({ payload: { searchQuery, redirect } }) {
	try {
		yield put(setIsAddressCreatingAction(true));

		const dataForSave = yield select(getDataForSaveSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createAddressRequest, dataForSave);

		if (data) {
			const str = removeModalQuery(searchQuery);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const rules = getFromStorage('rules');

			const query = new URLSearchParams(searchQuery);

			yield put(getAddressesAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
			if (rules.contacts?.create) {
				query.set('modal', 'contact');

				redirect(`/home/addresses?${query.toString()}&id=${data.id}`);
			} else {
				query.delete('modal');
				query.delete('mode');

				yield put(resetStateAction());
				redirect(`/home/addresses?${query.toString()}`);
			}
		} else {
			if (errors) {
				yield put(touch(ADDRESSES_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(ADDRESSES_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressCreatingAction(false));
	}
}

export function* updateAddressSaga({ payload: { id, searchQuery, redirect } }) {
	try {
		yield put(setIsAddressUpdatingAction(true));

		const dataForSave = yield select(getDateForUpdateSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(updateAddressRequest, id, dataForSave);
		if (data) {
			const str = removeModalQuery(searchQuery);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const addresses = yield select(getAddressesSelector());

			const updatedAddress = {
				...data,
				link: `/home/addresses?${str ? `${str}&` : ''}modal=address&id=${data.id}`,
				marker: `https://yandex.ru/maps/?pt=${data.geo_lon},${data.geo_lat}&z=18&l=map`,
			};

			yield put(
				setAddressesAction(
					addresses.map(address => {
						return address.id === data.id ? updatedAddress : address;
					}),
				),
			);

			yield put(setAddressInfoAction(data));

			const query = new URLSearchParams(searchQuery);
			query.delete('mode');

			redirect(`/home/addresses?${query.toString()}`);
			yield put(getAddressesAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			if (errors) {
				yield put(touch(ADDRESSES_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(ADDRESSES_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressUpdatingAction(false));
	}
}

export function* deleteAddressSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsAddressDeletingAction(true));

		const { data, message, toast } = yield call(deleteAddressRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const addresses = yield select(getAddressesSelector());

			yield put(setAddressesAction(addresses.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getAddressesAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressDeletingAction(false));
	}
}

export function* getAddressesSaga() {
	try {
		yield put(setIsAddressesOptionsLoadingAction(true));

		const searchQuery = yield select(getAddressSearchValueSelector());

		const response = yield call(getAddressesBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setAddressesOptionsAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressesOptionsLoadingAction(false));
	}
}

export function* getPartnerAddressInfoSaga({ payload: { partnerId, addressId } }) {
	try {
		yield put(setIsAddressInfoLoadingAction(true));

		const { data, message, toast } = yield call(getPartnerAddressInfoRequest, partnerId, addressId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setAddressInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressInfoLoadingAction(false));
	}
}

export function* createPartnerAddressSaga({ payload: { searchQuery, redirect, partnerId } }) {
	try {
		yield put(setIsAddressCreatingAction(true));

		const dataForSave = yield select(getDataForSaveSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(createPartnerAddressRequest, dataForSave, partnerId);

		if (data) {
			const str = new URLSearchParams(searchQuery);
			str.delete('modal');

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const addresses = yield select(getAddressesSelector());

			yield put(
				setAddressesAction([
					{
						...data,
						link: `/home/partners?${str ? `${str}&` : ''}modal=address&addressId=${data.id}`,
						marker: `https://yandex.ru/maps/?pt=${data.geo_lon},${data.geo_lat}&z=18&l=map`,
					},
					...addresses,
				]),
			);

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			yield put(setAddressInfoAction(data));

			const rules = getFromStorage('rules');

			const query = new URLSearchParams(searchQuery);

			if (rules.contacts?.access) {
				query.set('modal', 'contact');

				redirect(`/home/partners?${query.toString()}&addressId=${data.id}`);
			} else {
				query.set('modal', 'addresses');
				query.delete('mode');

				yield put(resetStateAction());
				redirect(`/home/partners?${query.toString()}`);
			}
		} else {
			if (errors) {
				yield put(touch(ADDRESSES_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(ADDRESSES_FORM_NAME, transformToValidationErrors(errors)));
			}

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressCreatingAction(false));
	}
}

export function* updatePartnerAddressSaga({ payload: { partnerId, addressId, searchQuery, redirect } }) {
	try {
		yield put(setIsAddressUpdatingAction(true));

		const dataForSave = yield select(getDateForUpdateSelector());

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const { data, errors, message, toast } = yield call(
			updatePartnerAddressRequest,
			dataForSave,
			partnerId,
			addressId,
		);

		if (data) {
			const str = removeModalQuery(searchQuery);

			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${str ? `&${str}` : ''}` } : {}),
					}),
				);
			}

			const addresses = yield select(getAddressesSelector());

			const updatedAddress = {
				...data,
				link: `/home/partners?${str ? `${str}&` : ''}modal=address&id=${data.id}`,
				marker: `https://yandex.ru/maps/?pt=${data.geo_lon},${data.geo_lat}&z=18&l=map`,
			};

			yield put(
				setAddressesAction(
					addresses.map(address => {
						return address.id === data.id ? updatedAddress : address;
					}),
				),
			);

			yield put(setAddressInfoAction(data));

			const query = new URLSearchParams(searchQuery);
			query.delete('mode');

			redirect(`/home/partners?${query.toString()}`);
		} else {
			if (errors) {
				yield put(touch(ADDRESSES_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(ADDRESSES_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressUpdatingAction(false));
	}
}

export function* deletePartnerAddressSaga({ payload: { addressId, partnerId, redirect } }) {
	try {
		yield put(setIsAddressDeletingAction(true));

		const { data, message, toast } = yield call(deletePartnerAddressRequest, partnerId, addressId);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const addresses = yield select(getAddressesSelector());

			yield put(setAddressesAction(addresses.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsAddressDeletingAction(false));
	}
}

export default function* addressesModalsSaga() {
	yield takeEvery(GET_ADDRESS_INFO, getAddressInfoSaga);
	yield takeEvery(CREATE_ADDRESS, createAddressSaga);
	yield takeEvery(UPDATE_ADDRESS, updateAddressSaga);
	yield takeEvery(DELETE_ADDRESS, deleteAddressSaga);
	yield takeEvery(GET_ADDRESSES_OPTIONS, getAddressesSaga);
	yield takeEvery(GET_PARTNER_ADDRESS_INFO, getPartnerAddressInfoSaga);
	yield takeEvery(CREATE_PARTNER_ADDRESS, createPartnerAddressSaga);
	yield takeEvery(UPDATE_PARTNER_ADDRESS, updatePartnerAddressSaga);
	yield takeEvery(DELETE_PARTNER_ADDRESS, deletePartnerAddressSaga);
}
