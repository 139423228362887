/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import AddButton from '../../../../components/images/add-button.svg';
import './styles.less';

export const DocumentsComponent = ({ fields, documentTypes, formValues, change }) => {
	if (fields.length === 0) {
		fields.push({});
	}

	const handleRemove = index => {
		if (fields.length > 1) {
			fields.remove(index);
		} else {
			change(`${fields.name}[0].label`, '');
			change(`${fields.name}[0].customName`, '');
		}
	};

	return (
		<>
			<Grid.Row className="document-component__row">
				<Grid.Column className="document-component__text">Требовать документы по доставке груза</Grid.Column>
			</Grid.Row>
			{fields.map((el, index) => (
				<Grid.Row className="document-component__row" key={el.id || `document-${index}`}>
					<Grid.Column width={6} className="document-component__column">
						Документ №{index + 1}
					</Grid.Column>
					<Grid.Column
						width={fields.get(index).label === '8-other' ? 4 : 8}
						className="document-component__column"
					>
						<div className="document-component__column-with-button">
							<Field
								name={`${el}.label`}
								type="text"
								component={FormSelectField}
								autoComplete="off"
								options={formValues.documentTypes || documentTypes}
								placeholder="Выберите документ"
								isWithoutSelectOnBlur
								search
								dropdownClassName="document-component__dropdown"
								noResultsMessage="Документ не найден"
							/>
						</div>
					</Grid.Column>
					{fields.get(index).label === '8-other' && (
						<Grid.Column width={4} className="document-component__column-name">
							<div className="document-component__input_row">
								<Field
									name={`${el}.customName`}
									component={FormInputField}
									placeholder="Название документа"
								/>
							</div>
						</Grid.Column>
					)}
					{index === fields.length - 1 && (
						<Grid.Column>
							<div className="document-component__close" onClick={() => handleRemove(index)} />
						</Grid.Column>
					)}
				</Grid.Row>
			))}

			<div className="document-component__button-row">
				<div className="document-component__button-row-text">Добавить документ</div>
				<img src={AddButton} alt="add-button" onClick={() => fields.push({})} />
			</div>
		</>
	);
};

DocumentsComponent.propTypes = {
	fields: PropTypes.object.isRequired,
	documentTypes: PropTypes.array.isRequired,
	change: PropTypes.func.isRequired,
	formValues: PropTypes.object,
};
