/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const SimpleTableComponent = ({ rows, columns, lastRowStatus, withOutMinHeight }) => {
	return (
		<div className={`simple-table ${withOutMinHeight ? ' simple-table__no-min-height' : ''}`}>
			<Table basic="very" className="simple-table__table">
				<Table.Body>
					{!rows.length && (
						<Table.Row>
							<Table.Cell className="simple-table__no-data">Данные не найдены</Table.Cell>
						</Table.Row>
					)}
					{rows?.map((row, index) => (
						<Table.Row
							key={row.id}
							className={`simple-table__table-row${
								lastRowStatus && index === rows.length - 1
									? ` simple-table__table-row_${lastRowStatus}`
									: ''
							}`}
						>
							{columns.map(({ id, width, customComponent: CustomComponent, ...columnData }) => {
								if (CustomComponent) {
									return (
										<Table.Cell key={id} className="simple-table__table-cell" style={{ width }}>
											<CustomComponent rowData={row} columnId={id} columnData={columnData} />
										</Table.Cell>
									);
								}

								return (
									<Table.Cell className="simple-table__table-cell" key={id} style={{ width }}>
										{row[id]}
									</Table.Cell>
								);
							})}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		</div>
	);
};

SimpleTableComponent.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			customComponent: PropTypes.elementType,
		}),
	),
	rows: PropTypes.arrayOf(
		PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number])),
	),
	lastRowStatus: PropTypes.oneOf(['success', 'danger', '']),
	withOutMinHeight: PropTypes.bool,
};

export { SimpleTableComponent };
