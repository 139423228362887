/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-confusing-arrow */
import { createSelector } from 'reselect';
import { getFormValues, isInvalid } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { initialState } from './reducer';
import { FORM_NAME } from './constants';

export const selectRelationshipSettingsModalDomain = state => state.relationshipSettingsReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getStagesSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ stages }) => {
		return stages;
	});

export const getIsStagesLoadingSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ isStagesLoading }) => isStagesLoading);

export const getDocumentTypesSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ documentTypes }) => documentTypes);

export const getDocumentTypesOptionsSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ documentTypes }) => {
		if (documentTypes) {
			return documentTypes.map(doc => ({
				key: doc.key,
				value: doc.key,
				text: doc.name,
			}));
		}

		return [];
	});

export const getRelationshipSettingInfoSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ relationshipSettings, stages, documentTypes }) => {
		if (!relationshipSettings) {
			return {};
		}

		const result = {
			data: {
				id: relationshipSettings.id,
				name: relationshipSettings.name,
				stages: [],
				documents: [],
			},
		};

		if (stages) {
			result.data.stages = stages.map(stage => {
				const foundStage = relationshipSettings.stages?.find(s => s.key === stage.key);

				return {
					key: stage.key,
					name: stage.name,
					value: foundStage ? foundStage.value : 0,
				};
			});
		}

		const isDoneEnabled = result.data.stages.some(stage => stage.key === 'done' && stage.value === 1);

		if (isDoneEnabled && relationshipSettings.documents.length && documentTypes) {
			result.data.documents = relationshipSettings.documents.map(doc => {
				const documentType = documentTypes.find(d => d.key === doc.key);

				return {
					key: doc.key,
					name: doc.key === '8-other' ? doc.name : documentType?.name || 'Неизвестный документ',
				};
			});
		}

		return result;
	});

export const getIsDocumentsTypesLoadingSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ isDocumentsTypesLoading }) => isDocumentsTypesLoading);

export const getIsFormSendingSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ isFormSending }) => isFormSending);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship_settings');
	});

export const getDataForRequestSelector = () =>
	createSelector(
		state => getFormValues(FORM_NAME)(state),
		formValues => formValues,
	);

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship_settings' && query?.tab === 'audit');
	});

export const getActiveRelationshipSettingIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) =>
		query?.modal === 'relationship_settings' ? query?.id : null,
	);

export const getIsRelationshipSettingAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship_settings' && query?.mode === 'add');
	});

export const getRelationshipSettingHeaderSelector = () =>
	createSelector(selectRelationshipSettingsModalDomain, ({ relationshipSettings }) => {
		if (!relationshipSettings) {
			return '-';
		}
		const { id, created_at } = relationshipSettings;

		return `Настройка №${id} от ${moment(created_at).format('DD.MM.YYYY')}`;
	});

export const getIsRelationshipSettingLoadingSelector = () =>
	createSelector(
		selectRelationshipSettingsModalDomain,
		({ isRelationshipSettingLoading }) => isRelationshipSettingLoading,
	);

export const getFormIsInvalidSelector = () =>
	createSelector(
		state => isInvalid(FORM_NAME)(state),
		isInvalid => isInvalid,
	);
export const getRelationshipSettingTabsSelector = () =>
	createSelector(
		selectRelationshipSettingsModalDomain,
		({ relationshipSettings }) => relationshipSettings?.tabs || {},
	);

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectRelationshipSettingsModalDomain,
		getFormValues(FORM_NAME),
		getIsRelationshipSettingAddFormOpenSelector(),
		getIsStagesLoadingSelector(),
		({ stages, relationshipSettings, documentTypes }, formValues, isAddMode, isStagesLoading) => {
			if (isAddMode) {
				if (isStagesLoading || !stages?.length) {
					return {};
				}

				const defaultStageValues = stages.reduce((acc, stage) => {
					acc[stage.key] = true;
					return acc;
				}, {});

				return {
					stages: defaultStageValues,
					documents: [],
				};
			}

			if (!stages) {
				return { documents: [] };
			}

			const defaultStageKeys = [
				'new',
				'approved',
				'select_company',
				'selected_company',
				'collected',
				'set_driver',
				'set_car',
				'fetch',
				'done',
			];

			const defaultStageValues = defaultStageKeys.reduce((acc, key) => {
				acc[key] = formValues?.[key] ?? true;
				return acc;
			}, {});

			const transformedStages = relationshipSettings?.stages?.reduce((acc, { key, value }) => {
				acc[key] = value === 1;
				return acc;
			}, {});

			const stagesValues = relationshipSettings
				? { ...defaultStageValues, ...transformedStages }
				: defaultStageValues;

			const documents = relationshipSettings
				? relationshipSettings.documents?.map(doc => {
						const documentType = documentTypes?.find(d => d.key === doc.key);

						if (doc.key === '8-other') {
							const otherDoc = relationshipSettings.documents.find(d => d.key === '8-other');

							return {
								key: doc.key,
								label: doc.key,
								customName: otherDoc?.name || '',
							};
						}

						return {
							key: doc.key,
							label: doc.key,
							customName: documentType?.text || '',
						};
				  }) || []
				: [];

			return {
				...formValues,
				...(relationshipSettings && {
					id: relationshipSettings.id,
					name: relationshipSettings.name,
				}),
				stages: stagesValues,
				documents,
			};
		},
	);

export const getFormattedSettingsDataSelector = () =>
	createSelector([getFormValues(FORM_NAME), getDocumentTypesSelector()], (formValues, documentTypes) => {
		const { name, stages = {}, documents = [], id } = formValues || {};

		const filteredDocuments = documents.filter(doc => doc.label);

		return {
			id,
			name,
			stages: Object.entries(stages).map(([key, value]) => ({ key, value })),
			documents: filteredDocuments.map(doc => {
				const fullDoc = documentTypes.find(d => d.key === doc.label) || {};

				const baseDoc = { key: doc.label };

				if (doc.label === '8-other') {
					baseDoc.name = doc.customName || fullDoc.name || doc.label;
				}

				return baseDoc;
			}),
		};
	});

export const getIsRelationshipSettingEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(
			!isEmpty(query) && query?.modal === 'relationship_settings' && Boolean(query?.id) && query?.mode === 'edit',
		);
	});
