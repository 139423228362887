import React from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { normalizePhone } from '../../utils';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import { FormDoublePhoneField } from '../../../../semantic-ui/components/form-double-phone-field';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const ProfileForm = ({ isUserInfoLoading, usersList, isUsersListLoading, isDisabled, onCheckboxChange }) => {
	return (
		<Grid className="profile-form">
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Должность
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="post"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите вашу должность"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Фамилия
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="last_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите фамилию"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Имя
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="first_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите имя"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Отчество
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="middle_name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите отчество"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Почта
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="email"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите адрес электронной почты"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Телефон
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<FormDoublePhoneField
						mainFieldName="phone"
						mainPlaceholder="+7 (___) ___-__-__"
						additionalFieldName="extension"
						mainNormalize={normalizePhone}
						additionalPlaceholder="Добавочный"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Telegram ID
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="telegram_id"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите Telegram ID"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Пароль
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="password"
						type="password"
						component={FormInputField}
						autoComplete="off"
						placeholder="Придумайте пароль"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Подтверждение пароля
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="password_confirmation"
						type="password"
						component={FormInputField}
						autoComplete="off"
						placeholder="Введите пароль повторно"
						loading={isUserInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Переадресация уведомлений
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="redirect_ids"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						noResultsMessage="Не найдено"
						placeholder="Переадресация уведомлений"
						options={usersList}
						multiple
						search
						loading={isUsersListLoading}
						isWithoutSelectOnBlur
						dropdownClassName="static"
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Получать уведомления
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="is_notify"
						component={FormCheckboxField}
						toggle
						type="checkbox"
						onChange={() => onCheckboxChange('is_notify')}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Получать уведомления To Do List
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="is_notify_todo"
						component={FormCheckboxField}
						toggle
						type="checkbox"
						disabled={isDisabled.isNotifyTodo}
						onChange={() => onCheckboxChange('is_notify_todo')}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="profile-form__row">
				<Grid.Column width={6} className="profile-form__column">
					Получать уведомления To Do List по компании
				</Grid.Column>
				<Grid.Column width={7} className="profile-form__column">
					<Field
						name="is_notify_todo_company"
						component={FormCheckboxField}
						toggle
						type="checkbox"
						disabled={isDisabled.isNotifyTodoCompany}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

ProfileForm.propTypes = {
	isUserInfoLoading: PropTypes.bool.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onCheckboxChange: PropTypes.func.isRequired,
};
