import React, { useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import reducer from './reducer';
import { emailResendAction, emailVerifyAction, getFormInfoAction, resetStateAction } from './actions';
import { getEmailVerifyInfoSelector } from './selectors';
import { EmailVerifyView } from './components/email-verify-view';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import { getFromStorage } from '../../components/sidebar/utils';

export const EmailVerifyContainerWrapper = ({
	onGetFormInfo,
	emailVerifyInfo,
	onEmailResend,
	onResetState,
	onEmailVerify,
}) => {
	useInjectSaga({ key: 'emailVerifyContainerSaga', saga });
	useInjectReducer({ key: 'emailVerifyContainerReducer', reducer });
	const user_data = getFromStorage('user_data');

	const history = useHistory();

	const { id, hash } = useParams();

	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		if (!user_data.email_verified_at) {
			setModalShow(true);
		}
	}, [user_data]);

	useEffect(() => {
		if (history.location.pathname.includes('/email/verify') && id && hash) {
			onEmailVerify({ id, hash, redirect: history.replace });
		} else {
			onGetFormInfo(history.replace);
		}

		return () => {
			onResetState();
		};
	}, []);

	const handleEmailResend = () => {
		onEmailResend(history.replace);
	};

	return (
		<ModalWindow
			isModalShown={modalShow}
			modalContent={<EmailVerifyView emailVerifyInfo={emailVerifyInfo} onEmailResend={handleEmailResend} />}
			style={{ width: '695px' }}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	emailVerifyInfo: getEmailVerifyInfoSelector(),
});

const mapDispatchToProps = {
	onGetFormInfo: getFormInfoAction,
	onEmailResend: emailResendAction,
	onEmailVerify: emailVerifyAction,
	onResetState: resetStateAction,
};

const EmailVerifyContainer = connect(mapStateToProps, mapDispatchToProps)(EmailVerifyContainerWrapper);

EmailVerifyContainerWrapper.propTypes = {
	onGetFormInfo: PropTypes.func.isRequired,
	onEmailResend: PropTypes.func.isRequired,
	onEmailVerify: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	emailVerifyInfo: PropTypes.object,
};

export default EmailVerifyContainer;
