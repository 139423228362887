import { CustomCell } from '../../components/custom-cell';

export const ADMIN_TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{
		title: 'Заказчик',
		dataIndex: 'shipper',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'shipper' }),
	},
	{ title: 'Перевозчик', dataIndex: 'carrier' },
	{ title: 'Договор', dataIndex: 'contract' },
	{ title: 'Условия', dataIndex: 'conditions' },
];

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{ title: 'Заказчик', dataIndex: 'shipper', render: (_, rowData) => CustomCell({ rowData, columnId: 'shipper' }) },
	{ title: 'Перевозчик', dataIndex: 'carrier' },
	{ title: 'Договор', dataIndex: 'contract' },
	{ title: '', dataIndex: '' },
];

export const SETTINGS_TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{ title: 'Название', dataIndex: 'name', render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }) },
];
export const NAV_PAGE_LIST = [
	{ text: 'Мои ТЭО', href: '/companies_relationship' },
	{ text: 'Настройка системы', href: '/relationship_settings' },
];

export const NAV_PAGE_LIST_CARGO = [{ text: 'Мои ТЭО', href: '/companies_relationship' }];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADMIN_ROLE_NUMBER = 1;
