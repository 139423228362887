import {
	RESET_STATE,
	SET_RELATIONSHIP_INFO,
	SET_IS_RELATIONSHIP_DELETING,
	SET_IS_RELATIONSHIP_INFO_LOADING,
	SET_IS_RELATIONSHIP_SAVING,
	SET_IS_SHIPPERS_LIST_LOADING,
	SET_SHIPPERS_LIST,
	SET_CARRIERS_LIST,
	SET_IS_CARRIERS_LIST_LOADING,
	SET_SETTINGS_LIST,
	SET_IS_SETTINGS_LIST_LOADING,
	SET_CATEGORIES_LIST,
	SET_IS_CATEGORIES_LIST_LOADING,
} from './actions';

export const initialState = {
	relationshipInfo: null,
	isRelationshipInfoLoading: false,
	isRelationshipDeleting: false,
	isRelationshipSaving: false,
	shippersList: [],
	isShippersListLoading: false,
	carriersList: [],
	isCarriersListLoading: false,
	settingsList: [],
	isSettingsListLoading: false,
	categoriesList: [],
	isCategoriesListLoading: false,
};

const relationshipModalReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_RELATIONSHIP_INFO:
			return {
				...state,
				relationshipInfo: payload,
			};
		case SET_IS_RELATIONSHIP_INFO_LOADING:
			return {
				...state,
				isRelationshipInfoLoading: payload,
			};
		case SET_IS_RELATIONSHIP_DELETING:
			return {
				...state,
				isRelationshipDeleting: payload,
			};
		case SET_IS_RELATIONSHIP_SAVING:
			return {
				...state,
				isRelationshipSaving: payload,
			};
		case SET_SHIPPERS_LIST:
			return {
				...state,
				shippersList: payload,
			};
		case SET_IS_SHIPPERS_LIST_LOADING:
			return {
				...state,
				isShippersListLoading: payload,
			};
		case SET_CARRIERS_LIST:
			return {
				...state,
				carriersList: payload,
			};
		case SET_IS_CARRIERS_LIST_LOADING:
			return {
				...state,
				isCarriersListLoading: payload,
			};
		case SET_SETTINGS_LIST:
			return {
				...state,
				settingsList: payload,
			};
		case SET_IS_SETTINGS_LIST_LOADING:
			return {
				...state,
				isSettingsListLoading: payload,
			};
		case SET_CATEGORIES_LIST:
			return {
				...state,
				categoriesList: payload,
			};
		case SET_IS_CATEGORIES_LIST_LOADING:
			return {
				...state,
				isCategoriesListLoading: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default relationshipModalReducer;
