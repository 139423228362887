import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { InfoIcon } from '../info-icon';

const CustomCell = ({ rowData, columnId }) => {
	if (columnId === 'id') {
		return (
			<div className="custom-cell__id">
				<InfoIcon popupContent={`№${rowData[columnId]}`} link={rowData.link} />
			</div>
		);
	}

	if (columnId === 'contact') {
		return (
			<div className="custom-cell__contact">
				<div className="custom-cell__contact-name">
					{rowData.contact.last_name} {rowData.contact.first_name}
				</div>
				<div className="custom-cell__contact-phone">{rowData.contact.phone}</div>
			</div>
		);
	}

	if (columnId === 'phone') {
		return (
			<a className="custom-modal-cell__link" href={`tel:${rowData.phone}`}>
				{rowData.phone}
			</a>
		);
	}

	if (columnId === 'name') {
		return (
			<Link className="custom-cell__link" to={rowData.link}>
				{rowData[columnId]}
			</Link>
		);
	}

	return rowData[columnId];
};

CustomCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomCell };
