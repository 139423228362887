/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { ModalWindow } from '../../../../semantic-ui/components/modal-window';
import ModalFooterButtons from '../../../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ProfileContent } from '../profile-content';
import { Audit } from '../../../../components/audit';

export const ProfileModalView = ({
	userInfo,
	isUserInfoLoading,
	isProfileFormOpen,
	onProfileFormOpenButtonClick,
	isModalShown,
	onNavItemClick,
	onModalClose,
	onProfileFormSubmit,
	onKeyDown,
	usersList,
	isUsersListLoading,
	isDisabled,
	onCheckboxChange,
	userName,
	isAuditTabShown,
	userId,
}) => {
	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={onKeyDown}
			headerText={isProfileFormOpen ? 'Изменить пользователя' : userName}
			navItems={
				isProfileFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => onNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => onNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<ProfileContent
						userInfo={userInfo}
						isUserInfoLoading={isUserInfoLoading}
						isProfileFormOpen={isProfileFormOpen}
						usersList={usersList}
						isUsersListLoading={isUsersListLoading}
						isDisabled={isDisabled}
						onCheckboxChange={onCheckboxChange}
					/>
				) : (
					<Audit entityName="users" entityId={userId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: onModalClose, text: 'profile' }]}
			footerButtons={
				<ModalFooterButtons
					rightButtons={
						isProfileFormOpen
							? [
									{
										onClick: onProfileFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isUserInfoLoading,
									},
									{
										onClick: () => onProfileFormOpenButtonClick(false),
										text: 'Отмена',
										color: 'secondary',
										disabled: isUserInfoLoading,
									},
							  ]
							: [
									...(!isProfileFormOpen && !isAuditTabShown
										? [
												{
													onClick: () => onProfileFormOpenButtonClick(true),
													text: 'Изменить',
													color: 'primary',
													disabled: isUserInfoLoading,
												},
										  ]
										: []),
									{
										onClick: onModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

ProfileModalView.propTypes = {
	userInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
			]).isRequired,
		}),
	),
	isUserInfoLoading: PropTypes.bool.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	isProfileFormOpen: PropTypes.bool.isRequired,
	onProfileFormOpenButtonClick: PropTypes.func.isRequired,
	onNavItemClick: PropTypes.func.isRequired,
	onModalClose: PropTypes.func.isRequired,
	onProfileFormSubmit: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
	usersList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
		}),
	).isRequired,
	isUsersListLoading: PropTypes.bool.isRequired,
	isDisabled: PropTypes.objectOf(PropTypes.bool).isRequired,
	onCheckboxChange: PropTypes.func.isRequired,
	userName: PropTypes.string.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	userId: PropTypes.number,
};
