import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { TodoView } from './todo-view';
import {
	getActiveNavItemSelector,
	getActiveToDoSelector,
	getBadgesSelector,
	getIsInfoCompanyCheckedSelector,
	getIsInformationLoadingSelector,
} from '../../selectors';
import { getInformationAction, setActiveNavItemAction, setIsInfoCompanyCheckedAction } from '../../actions';
import { NAV_ITEMS_TEMP } from '../../constants';

export const TodoWrapper = ({
	todo,
	isInformationLoading,
	activeNavItem,
	onSetActiveNavItem,
	isInfoCompanyChecked,
	onSetIsInfoCompanyCheckedAction,
	onGetInformation,
	badges,
}) => {
	const handleNavItemClick = navItem => {
		onSetActiveNavItem(navItem);
	};

	const handleInfoCompanyCheckboxChange = () => {
		onSetIsInfoCompanyCheckedAction(!isInfoCompanyChecked);
		onGetInformation();
	};

	const getNavItemsWithBadges = (navItems, badges, activeNavItem) =>
		navItems.map(({ key, value }) => {
			const count = badges?.[key] || 0;
			let color = null;

			if (count > 0) {
				color = value === activeNavItem ? '#ff6300' : '#a0a9a6cc';
			}

			return {
				key,
				value,
				count,
				color,
			};
		});

	const navItems = getNavItemsWithBadges(NAV_ITEMS_TEMP, badges, activeNavItem);

	return (
		<TodoView
			todo={todo}
			navItems={navItems}
			isInformationLoading={isInformationLoading}
			activeNavItem={activeNavItem}
			onNavItemClick={handleNavItemClick}
			isInfoCompanyChecked={isInfoCompanyChecked}
			onInfoCompanyCheckboxChange={handleInfoCompanyCheckboxChange}
		/>
	);
};

const mapStateToProps = createStructuredSelector({
	todo: getActiveToDoSelector(),
	isInformationLoading: getIsInformationLoadingSelector(),
	activeNavItem: getActiveNavItemSelector(),
	isInfoCompanyChecked: getIsInfoCompanyCheckedSelector(),
	badges: getBadgesSelector(),
});

const mapDispatchToProps = {
	onSetActiveNavItem: setActiveNavItemAction,
	onSetIsInfoCompanyCheckedAction: setIsInfoCompanyCheckedAction,
	onGetInformation: getInformationAction,
};

const TodoComponent = connect(mapStateToProps, mapDispatchToProps)(TodoWrapper);

TodoWrapper.propTypes = {
	todo: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			button: PropTypes.shape({
				text: PropTypes.string,
				color: PropTypes.string,
				link: PropTypes.string,
			}),
		}),
	),
	isInformationLoading: PropTypes.bool.isRequired,
	activeNavItem: PropTypes.string.isRequired,
	onSetActiveNavItem: PropTypes.func.isRequired,
	isInfoCompanyChecked: PropTypes.bool.isRequired,
	onSetIsInfoCompanyCheckedAction: PropTypes.func.isRequired,
	onGetInformation: PropTypes.func.isRequired,
	badges: PropTypes.number,
};

export { TodoComponent };
