/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { ADMIN_ROLE_NUMBER, ADMIN_TABLE_ROWS_NAMES, DATE_FORMAT, FORM_NAME, TABLE_ROWS_NAMES } from './constants';
import { getStringFromField } from './utils';
import { getLocalStorageItem } from '../../utils/localStorage';

export const selectRelationshipModalDomain = state => state.relationshipModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};
export const selectProfileModalDomain = state => state.profileModalReducer || {};

export const getIsAdminSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id === ADMIN_ROLE_NUMBER);

export const getCompanyIdSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id);

export const getCurrentCompanySelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => {
		const { company, companyId } = getLocalStorageItem('user_data');

		return userInfo?.company || { name: company, id: companyId };
	});

export const getRelationshipInfoSelector = () =>
	createSelector(selectRelationshipModalDomain, getIsAdminSelector(), ({ relationshipInfo }, isAdmin) => {
		if (!relationshipInfo) {
			return [];
		}

		const data = {
			id: relationshipInfo.id,
			shipper: relationshipInfo.from?.name || '-',
			carrier: relationshipInfo.to?.name || '-',
			contract: relationshipInfo.contract || '-',
			...(isAdmin
				? {
						insurance: getStringFromField(relationshipInfo.insurance),
						fee: getStringFromField(relationshipInfo.fee),
				  }
				: {}),
		};

		const rowsNames = isAdmin ? ADMIN_TABLE_ROWS_NAMES : TABLE_ROWS_NAMES;

		return Object.entries(data).map(([key, value]) => ({
			id: key,
			title: rowsNames[key] || key,
			value,
		}));
	});

export const getRelationshipSettingsSelector = () =>
	createSelector(selectRelationshipModalDomain, selectRouterDomain, ({ relationshipInfo }, { location }) => {
		if (!relationshipInfo?.settings) {
			return [];
		}

		return relationshipInfo.settings.map((el, index) => {
			const title = el.category?.name || '-';

			if (!el.setting) {
				return {
					id: `settings_id_${index}`,
					title,
					value: 'Стандартный жизненный цикл',
				};
			}

			const isDefaultSetting = el.setting?.name === 'Стандартный жизненный цикл';
			if (isDefaultSetting) {
				return {
					id: `settings_id_${index}`,
					title,
					value: el.setting?.name || '-',
				};
			}

			const query = new URLSearchParams(location.search);
			query.set('modal', 'relationship_settings');
			query.set('id', el.setting?.id);

			return {
				id: `settings_link_${index}`,
				title,
				value: {
					url: `${location.pathname}?${query}`,
					text: el.setting?.name,
				},
			};
		});
	});
export const getRelationshipNameSelector = () =>
	createSelector(selectRelationshipModalDomain, ({ relationshipInfo }) => {
		return relationshipInfo?.id && relationshipInfo?.created_at
			? `ТЭО №${relationshipInfo.id} от ${moment(relationshipInfo.created_at).format(DATE_FORMAT)}`
			: '-';
	});

export const getActiveRelationshipIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'relationship' ? query?.id : null;
	});

export const getIsOptionsListLoadingSelector = () =>
	createSelector(
		selectRelationshipModalDomain,
		({ isCarriersListLoading, isShippersListLoading, isSettingsListLoading, isCategoriesListLoading }) => ({
			shippers: isShippersListLoading,
			carriers: isCarriersListLoading,
			categories: isCategoriesListLoading,
			settings: isSettingsListLoading,
		}),
	);

export const getOptionsListSelector = () =>
	createSelector(selectRelationshipModalDomain, ({ shippersList, carriersList, settingsList, categoriesList }) => ({
		shippers: shippersList,
		carriers: carriersList,
		settings: settingsList,
		categories: categoriesList,
	}));

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectRelationshipModalDomain,
		getIsAdminSelector(),
		getCompanyIdSelector(),
		getOptionsListSelector(),
		({ relationshipInfo }, isAdmin, companyId, optionsList) => {
			const shipperID = !isAdmin ? companyId : '';

			const baseInitialValues = {
				shipper: relationshipInfo?.from?.id || shipperID || '',
				carrier: relationshipInfo?.to?.id || '',
				contract_number: relationshipInfo?.contract_number || '',
				contract_date: relationshipInfo?.contract_date || '',
				insurance: relationshipInfo?.insurance ?? '',
				fee: relationshipInfo?.fee ?? '',
			};

			const settingsInitialValues = optionsList.categories.reduce((acc, category) => {
				acc[`settings ${category.value}`] = 'none';
				return acc;
			}, {});

			relationshipInfo?.settings?.forEach(item => {
				if (item?.category?.id && item?.setting?.id) {
					settingsInitialValues[`settings ${item.category.id}`] = item.setting.id;
				}
			});

			const initialValues = {
				...baseInitialValues,
				...settingsInitialValues,
			};

			return initialValues;
		},
	);

export const getIsRelationshipInfoLoadingSelector = () =>
	createSelector(
		selectRelationshipModalDomain,
		({ isRelationshipInfoLoading, isRelationshipDeleting, isRelationshipSaving }) =>
			isRelationshipInfoLoading || isRelationshipDeleting || isRelationshipSaving,
	);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship' && query?.tab === 'audit');
	});

export const getIsRelationshipAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship' && query?.mode === 'add');
	});

export const getIsRelationshipEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(
			!isEmpty(query) && query?.modal === 'relationship' && Boolean(query?.id) && query?.mode === 'edit',
		);
	});

export const getDataForCreateOrUpdateRelationshipSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		const { shipper, carrier, ...othersFormValues } = formValues;

		const settings = Object.entries(othersFormValues)
			.filter(([key]) => key.startsWith('settings '))
			.map(([key, value]) => ({
				category_id: Number(key.slice(9)),
				setting_id: value === 'none' ? null : value,
			}));

		const filteredOthersFormValues = Object.fromEntries(
			Object.entries(othersFormValues).filter(([key]) => !key.startsWith('settings ')),
		);

		return {
			...filteredOthersFormValues,
			from_id: shipper,
			to_id: carrier,
			settings,
		};
	});
