/* eslint-disable no-mixed-spaces-and-tabs */
export const getFromStorage = key => {
	const item = localStorage.getItem(key);

	if (!item) return null;

	try {
		return JSON.parse(item);
	} catch (error) {
		return null;
	}
};

export const isAuthorized = () => {
	const token = getFromStorage('token');

	return Boolean(token);
};

export const getMenuItems = (items, activeRoute, activeSubmenus, reqsTotalCount, claimCount, reqsCategoryCount) => {
	const rules = getFromStorage('rules');

	const filtered = rules
		? items.filter(link => {
				if (link.id === 'home') return true;

				const route = link.route;

				const modal = link.modal;

				if (route) {
					return Object.keys(rules).some(key => {
						const rule = rules[key];

						return link.id === key && rule.access === true;
					});
				}

				if (modal) {
					return Object.keys(rules).some(key => {
						const rule = rules[key];

						return link.id === key && rule.access === true;
					});
				}

				if (link.submenu) {
					const filteredSubmenu = link.submenu.filter(sublink => {
						const subRoute = sublink.route;

						const subModal = sublink.modal;

						if (subRoute) {
							return Object.keys(rules).some(key => {
								const rule = rules[key];

								return sublink.id === key && rule.access === true;
							});
						}

						if (subModal) {
							return Object.keys(rules).some(key => {
								const rule = rules[key];

								return sublink.id === key && rule.access === true;
							});
						}

						return false;
					});

					link.submenu = filteredSubmenu;
					return filteredSubmenu.length > 0;
				}

				return false;
		  })
		: items;

	return filtered.map(item => {
		const submenu = rules
			? item.submenu?.map(submenuItem => {
					const subRoute = submenuItem.route;

					const ruleKeys = Object.keys(rules);

					const subId = submenuItem?.id;

					const matchingRuleKey = ruleKeys.find(key => {
						const rule = rules[key];

						return subRoute && subRoute.includes(key) && rule.access === true;
					});

					if (subId === 'reqs_claims') {
						if (!claimCount) {
							claimCount = null;
						}

						return {
							...submenuItem,
							isActive: activeRoute === submenuItem.route,
							claimCount,
						};
					}
					if (subId === 'req_combined') {
						return {
							...submenuItem,
							isActive: activeRoute === submenuItem.route,
							count: reqsCategoryCount?.combined?.total,
						};
					}
					if (subId === 'req_car') {
						return {
							...submenuItem,
							isActive: activeRoute === submenuItem.route,
							count: reqsCategoryCount?.car?.total,
						};
					}
					if (subId === 'req_express') {
						return {
							...submenuItem,
							isActive: activeRoute === submenuItem.route,
							count: reqsCategoryCount?.express?.total,
						};
					}
					if (subId === 'req_avia') {
						return {
							...submenuItem,
							isActive: activeRoute === submenuItem.route,
							count: reqsCategoryCount?.avia?.total,
						};
					}

					return matchingRuleKey
						? {
								...submenuItem,
								isActive: activeRoute === submenuItem.route,
						  }
						: submenuItem;
			  })
			: item.submenu;

		return {
			...item,
			submenu,
			isActive: item.route === activeRoute,
			isSubmenuOpen: activeSubmenus.includes(item.id),
			...(reqsTotalCount && item.id === 'reqs' ? { count: reqsTotalCount } : {}),
			...(reqsCategoryCount && item.submenu?.id === 'req_combined'
				? { count: reqsCategoryCount?.combined?.total }
				: {}),
			...(reqsCategoryCount && item.submenu?.id === 'req_car' ? { count: reqsCategoryCount?.car?.total } : {}),
			...(reqsCategoryCount && item.submenu?.id === 'req_express'
				? { count: reqsCategoryCount?.express?.total }
				: {}),
			...(reqsCategoryCount && item.submenu?.id === 'req_avia' ? { count: reqsCategoryCount?.avia?.total } : {}),
			...(claimCount && item.submenu?.id === 'reqs' ? { claimCount } : {}),
			...(claimCount && item.id === 'quality' ? { claimCount } : {}),
		};
	});
};
