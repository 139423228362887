import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { InfoIcon } from '../../../info-icon';

const CustomAccreditationCell = ({ rowData, columnId }) => {
	if (columnId === 'id') {
		return (
			<div className="custom-cell__id">
				<InfoIcon popupContent={`№${rowData[columnId]}`} link={rowData.link} />
			</div>
		);
	}

	return rowData[columnId];
};

CustomAccreditationCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
};

export { CustomAccreditationCell };
