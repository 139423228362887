import {
	RESET_STATE,
	SET_RELATIONSHIP_LIST,
	SET_IS_RELATIONSHIP_LIST_LOADING,
	SET_PAGINATION,
	SET_TABLE_SEARCH,
} from './actions';

export const initialState = {
	relationshipList: null,
	isRelationshipListLoading: false,
	pagination: null,
	tableSearch: '',
	relationshipSettingsList: null,
	isRelationshipSettingsListLoading: false,
};

const relationshipContainerReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_RELATIONSHIP_LIST:
			return {
				...state,
				relationshipList: payload,
			};
		case SET_IS_RELATIONSHIP_LIST_LOADING:
			return {
				...state,
				isRelationshipListLoading: payload,
			};
		case SET_PAGINATION:
			return {
				...state,
				pagination: payload,
			};
		case SET_TABLE_SEARCH:
			return {
				...state,
				tableSearch: payload,
			};
		case RESET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default relationshipContainerReducer;
