/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectRelationshipSettingsContainerDomain = state =>
	state.relationshipSettingsContainerReducer || initialState;

export const getPaginationSelector = () =>
	createSelector(selectRelationshipSettingsContainerDomain, ({ pagination }) => {
		if (!pagination || pagination?.total === 0) {
			return null;
		}

		return {
			currentPage: pagination.current_page,
			totalPages: pagination.last_page,
			perPage: pagination.per_page,
			total: pagination.total,
			from: pagination.current_page * pagination.per_page - (pagination.per_page - 1),
			to:
				pagination.current_page === pagination.last_page
					? pagination.total
					: pagination.current_page * pagination.per_page,
			isNextItem: true,
			isPrevItem: true,
			isLastItem: false,
			isFirstItem: false,
		};
	});

export const getTableSearchSelector = () =>
	createSelector(selectRelationshipSettingsContainerDomain, ({ tableSearch }) => tableSearch);

export const getRawPaginationSelector = () =>
	createSelector(selectRelationshipSettingsContainerDomain, ({ pagination }) => pagination);

export const getIsRelationshipSettingsListLoadingSelector = () =>
	createSelector(
		selectRelationshipSettingsContainerDomain,
		({ isRelationshipSettingsListLoading }) => isRelationshipSettingsListLoading,
	);

export const getRelationshipSettingsListSelector = () =>
	createSelector(selectRelationshipSettingsContainerDomain, ({ relationshipSettingsList }) => {
		if (!relationshipSettingsList || relationshipSettingsList.length === 0) {
			return [];
		}

		return relationshipSettingsList.map(systemSettings => ({
			...systemSettings,
			id: systemSettings.id,
			name: systemSettings.name || '-',
			documents: Array.isArray(systemSettings.documents) ? systemSettings.documents : [],
			stages: Array.isArray(systemSettings.stages) ? systemSettings.stages : [],
		}));
	});
