import { CustomCell } from '../../components/custom-cell';

export const TABLE_COLUMNS = [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{ title: 'ФИО', dataIndex: 'name', render: (_, rowData) => CustomCell({ rowData, columnId: 'name' }) },
	{ title: 'Роль', dataIndex: 'role', key: 'role', width: 200 },
	{ title: 'Должность', dataIndex: 'post', key: 'post', width: 130 },
	{
		title: 'Телефон',
		dataIndex: 'phone',
		key: 'phone',
		width: 130,
		render: (_, rowData) => CustomCell({ rowData, columnId: 'phone' }),
	},
	{ title: 'Почта', dataIndex: 'email', key: 'email' },
];

export const NAV_PAGE_LIST = [{ text: 'Пользователи', href: '/users' }];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';
