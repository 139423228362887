import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { reset, stopSubmit, touch } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { FIELDS_FOR_VALIDATE, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import {
	CREATE_USER,
	DELETE_USER,
	GET_COMPANIES_LIST,
	GET_USER_INFO,
	GET_USERS_LIST,
	GET_USERS_ROLES_LIST,
	setCompaniesListAction,
	setIsCompaniesListLoadingAction,
	setIsUserDeletingAction,
	setIsUserInfoLoadingAction,
	setIsUserSavingAction,
	setIsUsersListInModalLoadingAction,
	setIsUsersRolesListLoadingAction,
	setUserInfoAction,
	setUsersListInModalAction,
	setUsersRolesListAction,
	UPDATE_USER,
} from './actions';
import {
	createUserItemRequest,
	deleteUserRequest,
	getCompaniesDictionaryRequest,
	getUserRequest,
	getUsersListRequest,
	getUsersRolesListRequest,
	updateUserRequest,
} from '../../api/requests';
import { getActiveUserIdSelector, getDataForCreateOrUpdateUserSelector, getFormIsInvalidSelector } from './selectors';
import { getRawPaginationSelector, getUnformattedUsersListSelector } from '../users/selectors';
import { getUsersListAction, setPaginationAction, setUsersListAction } from '../users/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getUserInfoSaga({ id }) {
	try {
		yield put(setIsUserInfoLoadingAction(true));

		const { data, message, toast } = yield call(getUserRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setUserInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserInfoLoadingAction(false));
	}
}

export function* deleteUserSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsUserDeletingAction(true));

		const { data, message, toast } = yield call(deleteUserRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const users = yield select(getUnformattedUsersListSelector());
			yield put(setUsersListAction(users.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getUsersListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserDeletingAction(false));
	}
}

export function* createUserSaga({ payload: { redirect, resetState, searchQuery } }) {
	try {
		yield put(setIsUserSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateUserSelector());

		const { data, errors, message, toast } = yield call(createUserItemRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			yield put(setUserInfoAction(data));

			redirect();
			resetState();
			reset(FORM_NAME);
			yield put(getUsersListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserSavingAction(false));
	}
}

export function* updateUserSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsUserSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateUserSelector());

		const { data, errors, message, toast } = yield call(updateUserRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const users = yield select(getUnformattedUsersListSelector());

			const updatedUser = {
				...data,
				link: `/users?${searchQuery ? `${searchQuery}&` : ''}modal=user&id=${data.id}`,
			};

			yield put(
				setUsersListAction(
					users.map(car => {
						return car.id === data.id ? updatedUser : car;
					}),
				),
			);

			yield put(setUserInfoAction(data));
			redirect();
			reset(FORM_NAME);
			yield put(getUsersListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }));
		} else {
			yield put(touch(FORM_NAME, ...FIELDS_FOR_VALIDATE));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserSavingAction(false));
	}
}

export function* getCompaniesListSaga() {
	try {
		yield put(setIsCompaniesListLoadingAction(true));
		const { data, message, toast } = yield call(getCompaniesDictionaryRequest);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setCompaniesListAction([
					{ text: 'Нет', value: 'none', key: 'none' },
					...data?.map(company => ({
						text: company.name,
						value: company.id,
						key: `${company.name.trim()}-${company.id}`,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompaniesListLoadingAction(false));
	}
}

export function* getUsersRolesListSaga() {
	try {
		yield put(setIsUsersRolesListLoadingAction(true));
		const { data, message, toast } = yield call(getUsersRolesListRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setUsersRolesListAction(
					data?.map(role => ({
						text: role.name,
						value: role.id,
						key: `${role.name.trim()}-${role.id}`,
					})),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUsersRolesListLoadingAction(false));
	}
}

export function* getUsersListSaga() {
	try {
		yield put(setIsUsersListInModalLoadingAction(true));
		const { data, message, toast } = yield call(getUsersListRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const activeUserId = yield select(getActiveUserIdSelector());
			yield put(
				setUsersListInModalAction(
					data.reduce((list, { id, name }) => {
						if (+activeUserId !== id) {
							list.push({
								text: name,
								key: id,
								value: id,
							});
						}

						return list;
					}, []),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUsersListInModalLoadingAction(false));
	}
}

export default function* usersModalSaga() {
	yield takeEvery(GET_USER_INFO, getUserInfoSaga);
	yield takeEvery(DELETE_USER, deleteUserSaga);
	yield takeEvery(CREATE_USER, createUserSaga);
	yield takeEvery(UPDATE_USER, updateUserSaga);
	yield takeEvery(GET_COMPANIES_LIST, getCompaniesListSaga);
	yield takeEvery(GET_USERS_ROLES_LIST, getUsersRolesListSaga);
	yield takeEvery(GET_USERS_LIST, getUsersListSaga);
}
