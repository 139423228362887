import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch, change } from 'redux-form';
import { notificationInit } from '../../modules/notifications/actions';
import { FORM_FIELDS, FORM_NAME, REQUEST_ERROR_MESSAGE } from './constants';
import { transformToValidationErrors } from './utils';
import { DISMISS_TIME } from '../../api/constants';
import {
	CREATE_TRAILER,
	DELETE_TRAILER,
	GET_TRAILER_INFO,
	GET_TRAILER_TYPES,
	GET_MODES,
	GET_TONNAGES,
	setTrailerInfoAction,
	setTrailerTypesAction,
	setIsTrailerDeletingAction,
	setIsTrailerInfoLoadingAction,
	setIsTrailerSavingAction,
	setIsTrailerTypesLoadingAction,
	setIsModesLoadingAction,
	setIsTonnagesLoadingAction,
	setModesAction,
	setTonnagesAction,
	UPDATE_TRAILER,
	setIsEventDataLoadingAction,
	setEventDataAction,
	GET_EVENT_DATA,
} from './actions';
import {
	saveNewTrailerRequest,
	deleteTrailerRequest,
	getTrailerInfoRequest,
	getTrailerTypesRequest,
	getModesDictionaryRequest,
	getTonnagesDictionaryRequest,
	updateTrailerRequest,
	getTrailerEventRequest,
} from '../../api/requests';
import { getDataForCreateOrUpdateTrailerSelector, getFormIsInvalidSelector } from './selectors';
import { getTrailersListSelector, getRawPaginationSelector } from '../trailers/selectors';
import { setTrailersListAction, setPaginationAction, getTrailersListAction } from '../trailers/actions';
import { errorHandler } from '../../api/utils';
import { removeModalQuery } from '../../utils/removeModalQuery';

export function* getTrailerInfoSaga({ id }) {
	try {
		yield put(setIsTrailerInfoLoadingAction(true));

		const { data, message, toast } = yield call(getTrailerInfoRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setTrailerInfoAction(data));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerInfoLoadingAction(false));
	}
}

export function* deleteTrailerSaga({ payload: { id, redirect, query } }) {
	try {
		yield put(setIsTrailerDeletingAction(true));

		const { data, message, toast } = yield call(deleteTrailerRequest, id);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			const trailers = yield select(getTrailersListSelector());
			yield put(setTrailersListAction(trailers.filter(({ id }) => id !== data.id)));

			const pagination = yield select(getRawPaginationSelector());
			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total - 1 }));
			}

			redirect();
			yield put(getTrailersListAction({ searchQuery: removeModalQuery(query.toString()), noLoading: true }));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerDeletingAction(false));
	}
}

export function* createTrailerSaga({ payload: { redirect, resetState, searchQuery, returnData } }) {
	try {
		yield put(setIsTrailerSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateTrailerSelector());

		const { data, errors, message, toast } = yield call(saveNewTrailerRequest, dataForSave);

		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			yield put(setTrailerInfoAction(data));

			const trailers = yield select(getTrailersListSelector());
			yield put(
				setTrailersListAction([
					{
						...data,
						type_name: data.type?.name ?? '---',
						link: `/home/trailers?${searchQuery ? `${searchQuery}&` : ''}modal=trailer&id=${data.id}`,
					},
					...trailers,
				]),
			);

			const pagination = yield select(getRawPaginationSelector());

			if (pagination) {
				yield put(setPaginationAction({ ...pagination, total: pagination.total + 1 }));
			}

			// Если прицеп добавляется из карточки машины, то нужно вернуться назад на карточку машины
			if (returnData) {
				yield put(change('cars-form', 'trailer', data.id));
				returnData.history.push(`${returnData.pathname}?${returnData.query.toString()}`);
			} else {
				redirect();
			}
			yield put(
				getTrailersListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);

			resetState();
		} else {
			yield put(touch(FORM_NAME, ...FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerSavingAction(false));
	}
}

export function* updateTrailerSaga({ payload: { id, redirect, searchQuery } }) {
	try {
		yield put(setIsTrailerSavingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		if (isInvalid) return;

		const dataForSave = yield select(getDataForCreateOrUpdateTrailerSelector());

		const { data, errors, message, toast } = yield call(updateTrailerRequest, id, dataForSave);
		if (data) {
			if (toast) {
				yield put(
					notificationInit({
						id: uuidv4(),
						dismissAfter: DISMISS_TIME,
						...toast,
						...(toast?.link ? { link: `${toast?.link}${searchQuery ? `&${searchQuery}` : ''}` } : {}),
					}),
				);
			}

			const trailers = yield select(getTrailersListSelector());

			const updatedTrailer = {
				...data,
				type_name: data.type?.name ?? '---',
				link: `/home/trailers?${searchQuery ? `${searchQuery}&` : ''}modal=trailer&id=${data.id}`,
			};

			yield put(
				setTrailersListAction(
					trailers.map(trailer => {
						return trailer.id === data.id ? updatedTrailer : trailer;
					}),
				),
			);

			yield put(setTrailerInfoAction(data));
			redirect();
			yield put(
				getTrailersListAction({ searchQuery: removeModalQuery(searchQuery.toString()), noLoading: true }),
			);
		} else {
			yield put(touch(FORM_NAME, ...FORM_FIELDS));
			yield put(stopSubmit(FORM_NAME, transformToValidationErrors(errors)));

			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerSavingAction(false));
	}
}

export function* getTonnagesSaga() {
	try {
		yield put(setIsTonnagesLoadingAction(true));

		const { data, message, toast } = yield call(getTonnagesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTonnagesAction([
					{ text: 'Нет', value: 'none', key: 'none' },
					...data.map(tonnage => ({
						key: String(tonnage.id),
						value: String(tonnage.id),
						text: tonnage.name,
					})),
				]),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTonnagesLoadingAction(false));
	}
}

export function* getTypesSaga() {
	try {
		yield put(setIsTrailerTypesLoadingAction(true));

		const { data, message, toast } = yield call(getTrailerTypesRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setTrailerTypesAction(
					data.map(type => ({ key: String(type.id), value: String(type.id), text: type.name })),
				),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsTrailerTypesLoadingAction(false));
	}
}

export function* getModesDataSaga() {
	try {
		yield put(setIsModesLoadingAction(true));

		const { data, message, toast } = yield call(getModesDictionaryRequest, '?receive=all');

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(
				setModesAction(data.map(mode => ({ key: String(mode.id), value: String(mode.id), text: mode.name }))),
			);
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsModesLoadingAction(false));
	}
}

export function* getEventDataSaga({ payload: id }) {
	try {
		yield put(setIsEventDataLoadingAction(true));

		const { message, toast, reports } = yield call(getTrailerEventRequest, id);

		if (reports) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setEventDataAction(reports?.html || null));
		} else {
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsEventDataLoadingAction(false));
	}
}

export default function* trailersModalSaga() {
	yield takeEvery(GET_TRAILER_INFO, getTrailerInfoSaga);
	yield takeEvery(DELETE_TRAILER, deleteTrailerSaga);
	yield takeEvery(CREATE_TRAILER, createTrailerSaga);
	yield takeEvery(UPDATE_TRAILER, updateTrailerSaga);
	yield takeEvery(GET_TONNAGES, getTonnagesSaga);
	yield takeEvery(GET_TRAILER_TYPES, getTypesSaga);
	yield takeEvery(GET_MODES, getModesDataSaga);
	yield takeEvery(GET_EVENT_DATA, getEventDataSaga);
}
