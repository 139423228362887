/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Loader } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import './styles.less';
import { TableComponent } from '../../../../components/table-component';
import { TABLE_INFO_COLUMNS_NAMES, TABLE_INFO_NAME_COLUMNS_NAMES } from '../../constants';
import { SimpleTableComponent } from '../../../../components/simple-table';

const RelationshipSettingView = ({ relationshipSettings, isRelationshipSettingInfoLoading }) => {
	if (isRelationshipSettingInfoLoading || !relationshipSettings?.data) {
		return (
			<Grid className="relationship-setting-view">
				<Grid.Row className="relationship-setting-view__loader">
					<Loader active />
				</Grid.Row>
			</Grid>
		);
	}

	const { name, stages, documents } = relationshipSettings.data;

	const isDoneEnabled = stages.some(stage => stage.key === 'done' && stage.value === 1);

	const tableStagesRows = [
		...stages.map(stage => ({
			id: stage.key,
			label: stage.name,
			value: (
				<div className="relationship-setting-view__checkbox">
					<Field
						name={`stages.${stage.key}`}
						component={FormCheckboxField}
						toggle
						type="checkbox"
						disabled
						checked={Boolean(stage.value)}
					/>
				</div>
			),
		})),
	];

	const tableDocumentsRows = [
		...(isDoneEnabled && documents.length > 0
			? [
					...documents.map((doc, index) => ({
						id: doc.key,
						label: `Документ №${index + 1}`,
						value: doc.name,
					})),
			  ]
			: []),
	];

	return (
		<div>
			<SimpleTableComponent
				rows={[{ id: 'name', label: 'Название', value: name }]}
				columns={TABLE_INFO_NAME_COLUMNS_NAMES}
				withOutMinHeight
			/>
			<div className="relationship-setting-view__header_text_section">
				<h5>Жизненный цикл заявки</h5>
			</div>
			<TableComponent
				columns={TABLE_INFO_COLUMNS_NAMES}
				rows={tableStagesRows}
				isTableDataLoading={isRelationshipSettingInfoLoading}
				isWithoutHeader
				isChildren
				isInfo
				isWithoutTableHeader
			/>
			{isDoneEnabled && documents.length > 0 && (
				<>
					<div className="relationship-setting-view__header_text_section">
						<h5>Требовать документы по доставке груза</h5>
					</div>
					<SimpleTableComponent
						rows={tableDocumentsRows}
						columns={TABLE_INFO_NAME_COLUMNS_NAMES}
						withOutMinHeight
					/>
				</>
			)}
		</div>
	);
};

RelationshipSettingView.propTypes = {
	relationshipSettings: PropTypes.object,
	isRelationshipSettingInfoLoading: PropTypes.bool.isRequired,
	formValues: PropTypes.object,
};

export default RelationshipSettingView;
