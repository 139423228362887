/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../semantic-ui/components/form-input-field';
import { FormSelect } from '../../../../../semantic-ui/components/form-select';
import { FormTextAreaField } from '../../../../../semantic-ui/components/form-textarea-field';

export const AddressFormView = ({
	addressesOptions,
	isAddressesLoading,
	onAddressSearchChange,
	onAddressChange,
	addressesSearchValue,
	isAddressInfoLoading,
}) => {
	return (
		<div className="address-form">
			<div className="address-form__row">
				<span className="address-form__text address-form__text_right">Наименование склада</span>
				<div className="address-form__field">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите наименование склада"
						loading={isAddressInfoLoading}
					/>
				</div>
			</div>
			<div className="address-form__row">
				<span className="address-form__text address-form__text_right">Адрес склада</span>
				<div className="address-form__field">
					<Field
						name="address"
						type="text"
						component={FormSelect}
						autoComplete="off"
						placeholder="Укажите адрес склада"
						icon="search"
						options={addressesOptions}
						loading={isAddressesLoading || isAddressInfoLoading}
						noResultsMessage="Неизвестный адрес"
						onSearchChange={onAddressSearchChange}
						onItemChange={onAddressChange}
						searchValue={addressesSearchValue}
					/>
				</div>
			</div>
			<div className="address-form__row">
				<span className="address-form__text address-form__text_right">Режим работы</span>
				<div className="address-form__field">
					<Field
						name="work_mode"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите режим работы (ПН-ПТ, 09:00-19:00)"
						loading={isAddressInfoLoading}
					/>
				</div>
			</div>
			<div className="address-form__row">
				<span className="address-form__text address-form__text_right">Примечание</span>
				<div className="address-form__field">
					<Field
						name="comment"
						type="text"
						component={FormTextAreaField}
						autoComplete="off"
						placeholder="Укажите примечание"
						width="100%"
						resize="vertical"
						loading={isAddressInfoLoading}
					/>
				</div>
			</div>
		</div>
	);
};

AddressFormView.propTypes = {
	addressesOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string,
			data: PropTypes.shape({
				title: PropTypes.string.isRequired,
				address: PropTypes.string.isRequired,
				deleted: PropTypes.bool,
			}),
		}),
	).isRequired,
	isAddressesLoading: PropTypes.bool.isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	onAddressChange: PropTypes.func.isRequired,
	addressesSearchValue: PropTypes.string.isRequired,
	isAddressInfoLoading: PropTypes.bool.isRequired,
};
