import { SubmissionError } from 'redux-form';
import { DOCUMENTS_REQUIRED_FIELDS, REQUIRED_FIELDS } from './constants';
import { goToElement } from '../../utils/goToElement';

const validateDocuments = fields => {
	const documentErrors = { documents: [] };

	fields?.forEach((item, idx) => {
		DOCUMENTS_REQUIRED_FIELDS.forEach(key => {
			if (!item?.[key]) {
				if (!documentErrors?.documents?.[idx]) {
					documentErrors.documents[idx] = {};
				}
				documentErrors.documents[idx][key] = 'Заполните это поле';
				goToElement(`documents[${idx}].${key}`);
			}
		});
	});

	return documentErrors;
};

const validate = values => {
	const errors = {};

	if (!values.name) {
		errors.name = 'Название обязательно';
		goToElement('name');
	}

	REQUIRED_FIELDS.forEach(key => {
		if (key !== 'documents' && !values?.[key]) {
			errors[key] = 'Заполните это поле';
			goToElement(key);
		}
	});

	if (!errors.name && Object.keys(errors).length === 0) {
		const documentErrors = validateDocuments(values?.documents);

		if (Object.keys(documentErrors.documents).length > 0) {
			Object.assign(errors, documentErrors);
		}
	}

	if (Object.keys(errors).length > 0) {
		throw new SubmissionError(errors);
	}

	return errors;
};

export default validate;
