import { URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getTransportsListRequest = (type, searchQuery) =>
	makeRequest(`${URL}/api/quality_block/${type}${searchQuery ? `?${searchQuery}` : ''}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getTransportsFilterRequest = (type, parameters) =>
	makeRequest(
		`${URL}/api/quality_block/${type}/filter`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);
export const getSelectTransportAccreditationsRequest = (type, id) => {
	return makeRequest(`${URL}/api/${type}/${id}/selectAccreditation`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});
};

export const setSelectAccreditationRequest = (id, type, payload) => {
	return makeRequest(`${URL}/api/${type}/${id}/approveAccreditation`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify(payload),
	});
};

export const getAccreditationRequest = (type, id) => {
	return makeRequest(`${URL}/api/${type}/${id}/accreditations`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});
};
