export const GET_ACCREDITATION_DATA = 'accreditation/GET_ACCREDITATION_DATA';
export const getAccreditationtDataAction = payload => ({
	type: GET_ACCREDITATION_DATA,
	payload,
});

export const SET_ACCREDITATION_DATA = 'accreditation/SET_ACCREDITATION_DATA';
export const setAccreditationtDataAction = payload => ({
	type: SET_ACCREDITATION_DATA,
	payload,
});

export const SET_IS_ACCREDITATION_DATA_LOADING = 'accreditation/SET_IS_ACCREDITATION_DATA_LOADING';
export const setIsAccreditationtDataLoadingAction = payload => ({
	type: SET_IS_ACCREDITATION_DATA_LOADING,
	payload,
});

export const RESET_STATE = 'accreditation/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});
