import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const selectAccreditationComponentDomain = state => state.accreditationComponentReducer || initialState;

export const getAccreditationDataSelector = () =>
	createSelector(selectAccreditationComponentDomain, ({ accreditationData }) =>
		accreditationData?.map(data => ({
			...data,
			id: data.id,
			company_name: data.company.name,
			is_accredited: data.is_accredited ? 'Да' : 'Нет',
			approved_modes: data.approved_modes?.map(mode => `${mode.name}°C`).join(', '),
			unapproved_modes: data.unapproved_modes?.map(mode => `${mode.name}°C`).join(', '),
		})),
	);

export const getIsAccreditationDataLoadingSelector = () =>
	createSelector(selectAccreditationComponentDomain, ({ isAccreditationDataLoading }) => isAccreditationDataLoading);
