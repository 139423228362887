import { CustomCell } from './components/transport-custom-cell';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TABLE_COLUMNS = transportType => [
	{
		title: '#',
		dataIndex: 'id',
		width: 50,
		minWidth: 50,
		align: 'center',
		render: (_, rowData) => CustomCell({ rowData, columnId: 'id' }),
	},
	{ title: 'Компания', dataIndex: 'company_name', width: 200 },
	{ title: 'Модель', dataIndex: 'name' },
	{
		title: transportType === 'cars' ? 'Тип машины' : 'Тип прицепа',
		dataIndex: 'type_name',
	},
	{
		title: 'Гос. номер',
		dataIndex: 'number',
	},
	{
		title: transportType === 'cars' ? 'Машина аккредитована' : 'Прицеп аккредитован',
		dataIndex: 'is_accredited',
		width: 200,
	},
];
export const TRANSPORTS_TABS = [
	{ tab: 'total', text: 'Машины', href: '/quality_block/cars' },
	{ tab: 'total', text: 'Прицепы', href: '/quality_block/trailers' },
];

export const headerTextMap = {
	cars: 'Машины',
	trailers: 'Прицепы',
	default: 'Транспорт',
};
