import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import NotificationIcon from '../../../../components/images/notification-icon.svg';
import ProfileIcon from '../../../../components/images/profile-big-icon.svg';
import ChevronIcon from '../../../../components/images/chevron-down-big-icon.svg';
import MailIcon from '../../../../components/images/mail.svg';
import { PROFILE_NAV_ITEMS } from '../../constants';
import { NotificationsList } from '../notifications-list';
import { ChatsList } from '../../../chats-list';
import {
	TYPE_APP_ENV,
	TYPE_APP_ENV_DEV,
	TYPE_APP_ENV_LOCAL,
} from '../../../../components/page-content-with-nav/constants';

const RightHeaderContentView = ({
	isNotificationDropdownShown,
	isProfileDropdownShown,
	onProfileButtonClick,
	onNotificationsButtonClick,
	onNavItemClick,
	userData,
	notificationsCount,
	onMailButtonClick,
	isChatsListShownShown,
	chatsNotificationsCount,
}) => {
	return (
		<div className="right-header-content">
			{(TYPE_APP_ENV === TYPE_APP_ENV_LOCAL || TYPE_APP_ENV === TYPE_APP_ENV_DEV) && (
				<div style={{ textAlign: 'center', fontSize: 13, fontWeight: '500' }}>{userData.email}</div>
			)}
			<div className="right-header-content__container" onClick={onMailButtonClick}>
				{chatsNotificationsCount > 0 && (
					<div className="right-header-content__badge right-header-content__badge" />
				)}
				<img src={MailIcon} alt="mail-icon" />
			</div>
			<div className="right-header-content__container" onClick={onNotificationsButtonClick}>
				{notificationsCount > 0 && <div className="right-header-content__badge" />}
				<img src={NotificationIcon} alt="notification-icon" />
			</div>
			<div className="right-header-content__container" onClick={onProfileButtonClick}>
				<img src={ProfileIcon} alt="profile-icon" />
				<img src={ChevronIcon} alt="chevron-icon" />
			</div>
			{isProfileDropdownShown && (
				<div className="right-header-content__profile">
					<div className="right-header-content__profile-user">
						<div className="right-header-content__profile-user-name">
							{userData.last_name} {userData.first_name}
						</div>
						<div className="right-header-content__profile-user-email">{userData.email}</div>
					</div>
					<div className="right-header-content__profile-nav">
						{PROFILE_NAV_ITEMS.map(item => (
							<div
								className="right-header-content__profile-nav-item"
								key={item.id}
								onClick={() => onNavItemClick(item.id)}
							>
								<img src={item.icon} alt={item.id} />
								{item.text}
							</div>
						))}
					</div>
				</div>
			)}
			{isNotificationDropdownShown && <NotificationsList />}
			{isChatsListShownShown && <ChatsList />}
		</div>
	);
};

RightHeaderContentView.propTypes = {
	isNotificationDropdownShown: PropTypes.bool.isRequired,
	isProfileDropdownShown: PropTypes.bool.isRequired,
	onProfileButtonClick: PropTypes.func.isRequired,
	onNotificationsButtonClick: PropTypes.func.isRequired,
	onNavItemClick: PropTypes.func.isRequired,
	notificationsCount: PropTypes.number.isRequired,
	onMailButtonClick: PropTypes.func.isRequired,
	isChatsListShownShown: PropTypes.bool.isRequired,
	chatsNotificationsCount: PropTypes.number.isRequired,
	userData: PropTypes.shape({
		last_name: PropTypes.string.isRequired,
		first_name: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
	}),
};

export { RightHeaderContentView };
